import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import idAuth from "@/views/Home/personal/idAuth/idAuth.vue";
import apiManagement from "./apiManagement/apiManagement.vue";
import recommendInvite from "./recommendInvite/recommendInvite.vue";
import safetyRecord from "./safetyRecord/safetyRecord.vue";
import bindEmail from "./bindEmail/bindEmail.vue";
import changePhone from "./changePhone/changePhone.vue";
import bindGoogle from "./bindGoogle/bindGoogle.vue";
import changePassword from "./changePassword/changePassword.vue";
import userSettings from "./userSettings/userSettings.vue";
import Model from "@/components/model/modelContent.vue";
import {mapGetters, mapState} from "vuex";

export default {
    components: {
        Footer,
        PageNav,
        idAuth,
        apiManagement,
        recommendInvite,
        safetyRecord,
        userSettings,
        Model,
        bindEmail,
        changePhone,
        bindGoogle,
        changePassword,
    },
    data() {
        return {
            menuList: [
                {
                    id: 1,
                    text: this.$t('nav.aa1'),
                },
                {
                    id: 2,
                    text: this.$t('nav.as12'),
                },
                {
                    id: 3,
                    text: this.$t('nav.aa2'),
                },
                {
                    id: 4,
                    text: this.$t('nav.aa3'),
                },
                /*{
                    id: 5,
                    text: this.$t('nav.aa4'),
                },*/
            ],
            activeKey: 1,
            showImg: false,
            //控制主页面
            userManagement: true,
            // 控制子页面
            safetyRecord: false,
            bindEmail: false,
            changePhone: false,
            bindGoogle: false,
            changePassword: false,
            setting: [
                {
                    name: this.$t('us.a1'), //名称
                    des: this.$t('us.a2'),  //描述
                    status: {
                        name: this.$t('po.a72'),  //状态
                        color: "#ff9e2d",  //状态标识
                    },
                    btn: this.$t('us.a3'), //按钮名称
                    type: "primary", //按钮类型
                    ifBind: false, //是否绑定
                    ifShow: false, //控制子页面是否显示
                    component: "bindEmail", //子页面名称
                },
                {
                    name: this.$t('us.a4'),
                    des: this.$t('us.a5'),
                    status: {
                        name: this.$t('po.a72'),
                        color: "#ff9e2d",
                    },
                    btn: this.$t('us.a6'),
                    type: "primary",
                    ifBind: false,
                    ifShow: false,
                    component: "changePhone",
                },
                {
                    name: this.$t('us.a7'),
                    des: this.$t('us.a8'),
                    status: {
                        name: this.$t('po.a72'),
                        color: "#ff9e2d",
                    },
                    btn: this.$t('us.a3'),
                    type: "primary",
                    ifBind: false,
                    ifShow: false,
                    component: "bindGoogle",
                },
                {
                    name: this.$t('us.a10'),
                    des: this.$t('us.a11'),
                    status: {
                        name: this.$t('us.a9'),
                        color: "#ff9e2d",
                    },
                    btn: this.$t('us.a6'),
                    type: "primary",
                    ifBind: true,
                    ifShow: false,
                    component: "changePassword",
                },
            ],
            visible: false,
            eyeShow: false
        };
    },
    computed: {
        ...mapGetters(['userId', 'userNickname', 'userAccount', 'userTheme']),
        ...mapState(['userinfo'])
    },
    mounted() {
        console.log(this.userinfo);
        console.log(this.$route.params);
    },
    methods: {
        toSafety() {
            this.userManagement = false;
            this.safetyRecord = true;
        },
        numSelect(e) {
            this.decimal = e;
        },
        tabActive(e) {
            this.safetyRecord = false;
            this.activeKey = e;
        },
        goBack() {
            this.safetyRecord = false;
            this.bindEmail = false;
            this.changePhone = false;
            this.bindGoogle = false;
            this.changePassword = false;
            this.userManagement = true;
        },
        handleClick() {
            this.visible = true;
        },
        handleOkA() {
            console.log('确认')
            this.visible = false;
        },
        handleCancel() {
            this.visible = false;
        },
        ClickToBind(e) {
            switch (e.component) {
                case 'bindEmail':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.bindEmail = true
                        this.userManagement = false
                    }
                    break;
                case 'changePhone':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.changePhone = true
                        this.userManagement = false
                    }
                    break;
                case 'bindGoogle':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.bindGoogle = true
                        this.userManagement = false
                    }
                    break;
                case 'changePassword':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.changePassword = true
                        this.userManagement = false
                    }
                    break;
                default:
                    break;
            }
        }
    },
};