<template>
    <a-layout class="futures" :class="{ 'w-lte-1440': applyTestClass }" :data-theme="userTheme">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="futures_content">
            <!--        热门币种-->
            <div class="hotCoin">
                <img src="@/assets/hot.png" alt="">
                <div class="item" v-for="(item,index) in (currency[0] ? currency[0]['currency'].slice(0,15) : [])"
                     :key="index" @click="setCurrentCurrency(item)">
                    <span class="color-black" :class="tableActive === index ? 'font-bold' : ''">
                        {{ item.symbol }}{{ currency[0]['legal']['symbol'] }}
                    </span>
                    <span :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                        {{ item.change_rate }}%
                    </span>
                </div>
            </div>
            <div class="content_head">
                <div class="content_head_left flex flex-column align-center jus-center">
                    <a-trigger trigger="hover" :popup-translate="[100, 10]">
                        <div class="flex flex-row align-center coinSelectBox">
                            <!--<img class="mr-12 coinImage" :src="current['icon']" alt="">-->
							<!--切换-->
                            <div class="coinInfo">
                                <div class="row flex align-center">
                                    <span class="mr-10 font-bold color-black">
                                        {{current['symbol']}}{{currency[currencyTab] ? currency[currencyTab]['legal']['symbol'] : ''}}</span>
                                    <div class="caretDown">
                                        <icon-caret-down size="14"/>
                                    </div>
                                </div>
                                <span class="desc">{{$t('per.s11')}}</span>
                            </div>
                        </div>
						
                        <template #content>
                            <div class="market-wrap">
                                <a-input class="input" :placeholder="$t('per.s12')" v-model="searchVal" allow-clear>
                                    <template #suffix>
                                        <icon-search size="18"/>
                                    </template>
                                </a-input>
                                <!--               -->
                                <div class="market_tab flex align-center">
                                    <div v-for="(item,index) in currency" :key="'tab'+item.id"
                                         class="pad-lr-15 flex align-center jus-center pointer"
                                         :class="currencyTab === index ? 'active' : ''"
                                         @click="currencyTab = index">
                                        <span class="font-12 color-gray">{{ item['title']['en'] }}</span>
                                    </div>
                                </div>
								
                                <!--                -->
                                <div class="market_tab_main">
                                    <div class="sub_tab flex align-center jus-bet">
                                        <div class="flex align-center pointer">
                                            <span class="font-12 color-gray">{{$t('per.s13')}}</span>
                                        </div>
                                        <div class="flex align-center jus-end pointer">
                                            <span class="font-12 color-gray">{{$t('per.s14')}}</span>
                                            <img src="@/assets/images/ranking.svg">
                                        </div>
                                        <div class="flex align-center jus-end pointer">
                                            <span class="font-12 color-gray">{{$t('per.s15')}}</span>
                                            <img src="@/assets/images/ranking.svg">
                                        </div>
                                    </div>
									
									<!--列表-->
                                    <a-scrollbar outer-class="scrollbar">
                                        <div class="sub_cell flex align-center jus-bet"
                                             v-for="(item,index) in (searchVal !== '' ? currency[currencyTab]['currency'].filter((item)=>{return item.symbol.toLowerCase().includes(searchVal.toLowerCase())}):currency[currencyTab]['currency'])"
                                             :key="'c'+item.id"
                                             @click="setCurrentCurrency(item)">
											 
                                            <div class="flex align-center">
                                                <img class="mr-5" src="@/assets/images/spot/unlike.svg" alt=""
                                                     v-if="like">
                                                <img class="mr-5" src="@/assets/images/spot/like.svg" alt="" v-else>
                                                <span class="font-12 color-black mr-5">{{ item['symbol'] }}</span>
                                                <span class="font-12 color-gray">{{$t('per.s11')}}</span>
                                            </div>
                                            <div class="flex align-center jus-end">
                                                <span class="font-12"
                                                      :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                                                    {{ $util.thousandSeparator(item['new_price'], item.decimals) }}
                                                </span>
                                            </div>
                                            <div class="flex align-center jus-end">
                                                <span class="font-12"
                                                      :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                                                    {{ $util.thousandSeparator(item['change_rate'], 2) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </div>
                        </template>
						
                    </a-trigger>
                </div>
                <div class="content_head_right flex flex-row align-center jus-center">
                    <div class="content_head_right_info">
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-18 font-bold"
                                  :class="{'color-green':current.change_rate >= 0,'color-red':current.change_rate < 0}">
                                {{ $util.thousandSeparator(current['new_price'], current['decimal']) }}
                            </span>
                        </div>
                        <!--          24h涨跌幅-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray pointer">{{$t('per.s15')}}</span>
                            <span class="font-12 mt-5"
                                  :class="{'color-green':current.change_rate >= 0,'color-red':current.change_rate < 0}">
                                {{ $util.thousandSeparator(current['change_rate'], 2) }}%
                            </span>
                        </div>
                        <!--          标记价格-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer">{{$t('per.s16')}}</span>
                                <template #content>
                                    <p class="font-12 color-always-white">
                                        {{$t('per.s17')}}</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">
                                {{$util.thousandSeparator(market['p'],current['decimals'])}}
                            </span>
                        </div>
                        <!--          指数价格-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer"> {{$t('per.s18')}}</span>
                                <template #content>
                                    <p class="font-12 color-always-white"> {{$t('per.s19')}}</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">
                                {{$util.thousandSeparator(market['i'],current['decimals'])}}
                            </span>
                        </div>
                        <!--          本期资金费率/结算时间-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer">{{$t('per.s20')}}</span>
                                <template #content>
                                    <p class="font-12 color-always-white flex jus-bet">{{$t('per.s21')}}：<strong>
<!--                                        9月24日 0时0分(04:30:20)-->
                                        {{ $util.dateFormatter(market['T'], 'm月d日 h時i分', 2) }}
                                    </strong></p>
                                    <p class="font-12 color-always-white flex jus-bet">{{$t('per.s22')}}({{market['r'] >0 ? $t('dc.a1'):$t('dc.a1')}})：<strong>{{$util.float(market['r'] * 100)}}%</strong>
                                    </p>
                                    <p class="font-14 color-always-white font-bold">{{$t('per.s23')}}</p>
                                    <p class="font-12 color-always-white">
                                        {{$t('per.s24')}}</p>
                                    <p class="font-12 color-always-white">
                                         {{$t('per.s25')}}</p>
                                    <p class="font-12 color-always-white"> {{$t('per.s26')}}</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">{{$util.thousandSeparator(market['r'] * 100)}}%/{{timeInt}}</span>
                        </div>
                        <!--          24H最高价-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">{{$t('per.s27')}}</span>
                            <span class="font-12 color-black mt-5">
                                 {{ $util.thousandSeparator(current['high_price'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H最低价-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">{{$t('per.s28')}}</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['low_price'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H成交量（BTC）-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">{{$t('per.s29')}}（{{ current['symbol'] }}）</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['quantity'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H成交额（USD）-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">{{$t('per.s30')}}（USD）</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['quota'], current['decimals']) }}
                            </span>
                        </div>
                    </div>
                    <div class="rightButtons">
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="aboutCoinShow">
                                <icon-bookmark size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">{{ coinName }}USD{{$t('per.s31')}}</p>
                            </template>
                        </a-tooltip>
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="settingDrawer = true">
                                <icon-settings size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">{{$t('per.s32')}}</p>
                            </template>
                        </a-tooltip>
                    </div>
                </div>
            </div>
            <div class="content_main mt-10 flex jus-bet">
                <!--  -->
                <div class="content_main_left">
                    <div class="left_top flex flex-row jus-bet">
                        <!--            chart-->
                        <div class="block_kline">
                            <!--              table-->
                            <div class="kline_table"></div>
                            <!--              kline box-->
                            <div class="kline_box flex align-center jus-center">
                                <div class="chartBox" id="chartBox" style="width: 100%;height:100%"></div>
                            </div>
                        </div>
                        <!--      委托列表/实时成交      -->
                        <div class="block_list">
                            <a-tabs default-active-key="1">
                                <a-tab-pane key="1" :title="$t('per.s33')">
                                    <div class="_top pad-lr-15 flex flex-row align-center jus-bet">
                                        <div class="flex align-center listTagGroup">
                                            <a-tooltip :content="$t('per.s34')" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 0 ? 'item-active' : ''" @click="listType = 0">
                                                    <img src="@/assets/images/cut-top-bottom.svg">
                                                </div>
                                            </a-tooltip>
                                            <a-tooltip :content="$t('per.s35')" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 1 ? 'item-active' : ''" @click="listType = 1">
                                                    <img src="@/assets/images/cut-buy.svg">
                                                </div>
                                            </a-tooltip>
                                            <a-tooltip :content="$t('per.s36')" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 2 ? 'item-active' : ''" @click="listType = 2">
                                                    <img src="@/assets/images/cut-ask.svg">
                                                </div>
                                            </a-tooltip>
                                        </div>
                                        <a-dropdown trigger="hover" @select="numSelect">
                                            <div class="flex align-center jus-end numSelect">
                                                <span class="mr-5">{{ decimal }}</span>
                                                <icon-caret-down/>
                                            </div>
                                            <template #content>
                                                <a-doption>0.000001</a-doption>
                                                <a-doption>0.00001</a-doption>
                                                <a-doption>0.0001</a-doption>
                                            </template>
                                        </a-dropdown>
                                    </div>
                                    <!--                -->
                                    <div class="list-main">
										
										<a-scrollbar>
											<div class="down_group group_box" v-if="listType == 0 || listType == 2">
												<div class="_cell flex align-center jus-bet" type="title">
													<span class="font-12 color-gray">{{$t('per.s37')}}</span>
													<span class="font-12 color-gray">{{$t('per.s38')}} ({{ current['symbol'] }})</span>
													<span class="font-12 color-gray">{{$t('per.s39')}} (USD)</span>
												</div>
												
												<div v-if="listType === 2">
													<div class="_cell flex align-center jus-bet" type="title">
														<span class="font-12 color-gray">{{$t('per.s40')}}</span>
														<a-tooltip position="bottom" background-color="var(--tooltip-bg)">
															<span class="font-12 color-gray pointer dotted">{{$t('per.s41')}}</span>
															<template #content>
																<span class="font-12 color-always-white">
																	{{$t('per.s42')}}
																</span>
															</template>
														</a-tooltip>
													</div>
													<div class="_cell flex align-center jus-bet mb-5">
														 <span class="font-16 color-green font-bold">
															{{ $util.thousandSeparator(current['new_price'],current['decimals']) }}
															 <!--                                                        <icon-arrow-up style="margin-top: 1px"/>-->
														</span>
														<span class="font-16 color-gray font-bold">
															{{ $util.thousandSeparator(market['p'],current['decimals']) }}
														</span>
													</div>
												</div>
												<!-- 买入 -->
												<a-scrollbar :class="listType == 0 ? 'minList' : 'priceDownList'">
													<div class="_cell flex align-center jus-bet pointer hover-bg"
														 v-for="(item,index) in bids.slice(0, vw < 1750 ? 3 : 999)"
														 :key="'bids'+index">
														<span class="font-12 color-red">{{ $util.thousandSeparator(item[0],current['decimals']) }}</span>
														<span class="font-12 color-black">{{ item[1] }}</span>
														<span class="font-12 color-black">{{ $util.thousandSeparator(item[0] * item[1],2) }}</span>
														<!-- 背景盒子     -->
														<div class="_cell_bg red_bg"
															 :style="{'--width':item[3] * 100 + '%' }"></div>
													</div>
												</a-scrollbar>
											</div>
											
											<div class="up_group group_box" v-if="listType == 1 || listType == 0">
												<div>
													
													<div class="_cell flex align-center jus-bet" type="title">
														<span class="font-12 color-gray"> {{$t('per.s43')}}</span>
														<a-tooltip position="bottom" background-color="var(--tooltip-bg)">
															<span class="font-12 color-gray pointer dotted">{{$t('per.s44')}}</span>
															<template #content>
																<span class="font-12 color-always-white">
																	{{$t('per.s45')}}
																</span>
															</template>
														</a-tooltip>
													</div>
													
													<!--价格-->
													<div class="_cell flex align-center jus-bet mb-5">
														<span class="font-16 color-green font-bold">
															{{ $util.thousandSeparator(current['new_price'],current['decimals']) }}
	<!--                                                        <icon-arrow-up style="margin-top: 1px"/>-->
														</span>
														<span class="font-16 color-gray font-bold">
															{{ $util.thousandSeparator(current['new_price'],current['decimals']) }}
														</span>
													</div>
												</div>
												
												<!-- 卖出  -->
												<a-scrollbar :class="listType === 0 ? 'minList' : 'maxList'">
													<div class="_cell flex align-center jus-bet pointer hover-bg"
														 v-for="(item,index) in asks.slice(0, vw < 1750 ? 3 : 999)"
														 :key="'a'+index">
														<span class="font-12 color-green">{{ $util.thousandSeparator(item[0],current['decimals']) }}</span>
														<span class="font-12 color-green">{{ item[1] }}</span>
														<span class="font-12 color-green">{{ $util.thousandSeparator(item[0] * item[1],2) }}</span>
														<!-- 背景盒子     -->
														<div class="_cell_bg red_bg"
															 :style="{'--width':item[3] * 100 + '%' }"></div>
													</div>
												</a-scrollbar>
											</div>
											
										</a-scrollbar>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2" :title="$t('per.s46')">
                                    <div class="list-main">
                                        <div class="down_group group_box">
                                            <div class="_cell flex align-center jus-bet" type="title">
                                                <span class="font-12 color-gray">{{$t('per.s47')}}</span>
                                                <span class="font-12 color-gray">{{$t('per.s48')}} ({{ coinName }})</span>
                                                <span class="font-12 color-gray">{{$t('per.s49')}}</span>
                                            </div>
                                            <a-scrollbar class="scrollbar">
                                                <!--                      -->
                                                <div class="_cell flex align-center jus-bet pointer hover-bg"
                                                     v-for="(item,index) in trades"
                                                     :key="'t'+index">
                                                      <span class="font-12" :class="item.m ? 'color-red' : 'color-green'">
                                                          {{item.price }}
                                                      </span>
                                                    <span class="font-12 color-black">{{ item.vol }}</span>
                                                    <span class="font-12 color-black">{{ item.time }}</span>
                                                    <!-- 背景盒子     -->
                                                    <div class="_cell_bg"
                                                         :class="index % 2 === 0 ? 'red_bg' : 'green-bg'"
                                                         :style="{'--width':item.price * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                    <div class="left_bottom">
                        <!--   持仓/当前委托... -->
                        <div class="left_bottom_card">
                            <a-tabs animation default-active-key="1" @change="tabChange">
                                <a-tab-pane key="1" :title="`position(${isLogin ? position.position.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="first">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s50')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s51')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">{{$t('per.s52')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s53')}}
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                             {{$t('per.s54')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">{{$t('per.s55')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s56')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
<!--                                            <div class="tableItem">-->
<!--                                                <a-tooltip type="subtip" position="bottom"-->
<!--                                                           background-color="var(&#45;&#45;tooltip-bg)">-->
<!--                                                    <span class="font-12 color-gray dotted pointer">标记价格</span>-->
<!--                                                    <template #content>-->
<!--                                                        <p class="font-12 color-always-white">-->
<!--                                                            标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。-->
<!--                                                        </p>-->
<!--                                                        <p class="font-12 color-always-white">-->
<!--                                                            标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。-->
<!--                                                        </p>-->
<!--                                                    </template>-->
<!--                                                </a-tooltip>-->
<!--                                            </div>-->
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">{{$t('per.s57')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s58')}}</p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer"> {{$t('per.s59')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s60')}}
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                             {{$t('per.s61')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer"> {{$t('per.s62')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s63')}}  &lt;=
                                                            {{$t('per.s64')}} 
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s65')}}  &lt;=  {{$t('per.s66')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem" style="width: 150px">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span
                                                            class="font-12 color-gray dotted pointer">{{$t('per.s67')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s68')}}
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s69')}}
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s70')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s71')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s72')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">{{$t('per.s73')}}</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            {{$t('per.s74')}}
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                        <notdate v-if="isLogin && position.position.length <= 0" :msg="$t('per.s75')"></notdate>
                                        <div class="listWarp" type="first">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.position" :key="'p'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coinBefore bg-red"></span>
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                        <a-tag color="var(--opacity-gray)">
                                                            <span class="color-black">{{item['margin_type'] === 1 ? 'all':'out'}}{{item['muilt']}}X</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  持仓方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" :class="{'color-red':item['direction']===2,'color-green':item['direction']!==2}">{{item['direction'] === 1 ? $t('po.a1'):$t('po.a2')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  仓位/开平 -->
                                                    <div class="cellItem">
                                                        <span class="color-red">
                                                            {{$util.float(item['amount'])}}/{{$util.thousandSeparator(item['amount'] - item['close'],8)}} {{item['symbol']}}
                                                        </span>
                                                    </div>
                                                    <!--  持仓均价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['price'])}}
                                                        </span>
                                                    </div>
                                                    <!--  标记价格 -->
<!--                                                    <div class="cellItem">-->
<!--                                                        <span class="color-black">-->
<!--                                                            {{$util.thousandSeparator(current['new_price'],current['decimals'])}}-->
<!--                                                        </span>-->
<!--                                                    </div>-->
                                                    <!--  预估强平价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['stopPrice'],current['decimals'])}}
                                                        </span>
                                                    </div>
                                                    <!--  保证金 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.float(item['margin'])}}
                                                        </span>
                                                    </div>
                                                    <!--  保证金率 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item['mr']}}%</span>
                                                    </div>
                                                    <!--  持仓中盈亏/回报率 -->
                                                    <div class="cellItem" style="width: 150px">
                                                        <span class="mr-5" :class="{'color-red':item['p'] <= 0 ,'color-green':item['p']>0}">{{item['p']}}</span>/
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span :class="{'color-red':item['p'] <= 0 ,'color-green':item['p']>0}">{{item['rate']}}%</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  已实现盈亏 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.thousandSeparator(item['p'])}} USD</span>
                                                    </div>
                                                    <div class="cellItem">
                                                        <span class="color-primary cursor mr-5"
                                                              @click="pindex = index;closePotion = true"> {{$t('per.s76')}}</span>
                                                        <span class="color-primary cursor" @click="pindex = index;flashClose = true">{{$t('per.s77')}}</span>
                                                    </div>
                                                    <div class="cellItem">
                                                        <span class="mr-5 color-black">
                                                            {{item['winPrice'] > 0 ? $util.thousandSeparator(item['winPrice']):'--'}}/{{item['losePrice'] > 0 ? $util.thousandSeparator(item['losePrice']):'--'}}
                                                        </span>
                                                        <icon-edit class="cursor color-black"
                                                                   @click="pindex = index;profitLossPop = true"/>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="2" :title="`Current commission(${isLogin && position.entrust ? position.entrust.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab2">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray"> {{$t('per.s78')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray"> {{$t('per.s79')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray"> {{$t('per.s80')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 230px !important;">
                                                <span class="font-12 color-gray">{{$t('per.s81')}}</span>
                                            </div>
                                            <div class="tableItem" >
                                                <span class="font-12 color-gray">{{$t('per.82')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s83')}}</span>
                                            </div>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem">
                                                    <span class="font-12 color-gray dotted pointer">{{$t('per.s84')}}</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        {{$t('per.s85')}}
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                            <div class="tableItem" style="width: 194px !important;">
                                                <span class="font-12 color-gray">{{$t('per.s86')}}</span>
                                            </div>
                                            <div class="tableItem" style="justify-content: flex-end;text-align: right">
                                                <span class="font-12 color-gray">{{$t('per.s87')}}</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab2">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && position.entrust.length <= 0" :msg="$t('per.s88')"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.entrust" :key="'e'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" :class="{'color-red':item['direction']===2,'color-green':item['direction']!==2}">{{item['direction'] === 1 ? $t('po.a1'):$t('po.a2')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  类型 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$t('per.s89')}}</span>
                                                    </div>
                                                    <!--  成交均价/委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.t_price)}}/{{$util.thousandSeparator(item.price,item.decimals)}}</span>
                                                    </div>
                                                    <!--  成交数量/委托数量 -->
                                                    <div class="cellItem" style="width: 120px">
                                                        <span class="color-black">0.00/{{$util.float(item.amount)}}</span>
                                                    </div>
                                                    <!--  完成度 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">0%</span>
                                                    </div>
                                                    <!--  止盈价/止损价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.winPrice)}}/{{$util.float(item.losePrice)}}</span>
                                                    </div>

<!--                                                    <div class="cellItem">-->
<!--                                                        <span class="color-black" v-if="i === 1">&#45;&#45;/&#45;&#45;</span>-->
<!--                                                        <span class="color-black" v-if="i != 1">0.6500/0.6990</span>-->
<!--                                                    </div>-->
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  撤单 -->
                                                    <div class="cellItem" style="text-align: left;justify-content: flex-start">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;wdOrder = true">{{$t('per.s90')}}</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="3" :title="`Plan delegation(${isLogin && position.entrustP ? position.entrustP.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab3">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s91')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s92')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s93')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s94')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 186px">
                                                <span class="font-12 color-gray">{{$t('per.s95')}}</span>
                                            </div>
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">类型</span>-->
<!--                                            </div>-->
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s96')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s97')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('per.s98')}}</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab3">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && position.entrustP.length <= 0" :msg="$t('per.s99')"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.entrustP" :key="'ep'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem" style="width: 143px !important;">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" v-if="item.type === 1">{{$t('po.a1')}}</span>
                                                            <span class="color-red" v-if="item.type === 2">{{$t('po.a2')}}</span>
                                                            <span class="color-red" v-if="item.type === 3">{{$t('po.a3')}}</span>
                                                            <span class="color-red" v-if="item.type === 4">{{$t('po.a4')}}</span>
                                                            <span class="color-red" v-if="item.type === 5">{{$t('po.a5')}}</span>
                                                            <span class="color-red" v-if="item.type === 6">{{$t('po.a6')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  触发价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.wprice)}}</span>
                                                    </div>
                                                    <!--  委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.price === '' ? $util.float(item.wprice):$util.float(item.wprice)}}
                                                        </span>
                                                    </div>
                                                    <!--  委托数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.amount)}} {{item.symbol}}</span>
                                                    </div>
                                                    <!--  提交时间 -->
                                                    <div class="cellItem" style="width: 143px !important;">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  过期时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.getDateAddDay(item.created_at,30)}} 00:00:00</span>
                                                    </div>
                                                    <!--  撤单 -->
                                                    <div class="cellItem">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;wdOrder = true">{{$t('po.a7')}}</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="4" :title="$t('po.a8')">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab4">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a9')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a10')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a11')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 195px">
                                                <span class="font-12 color-gray">{{$t('po.a12')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 195px">
                                                <span class="font-12 color-gray">{{$t('po.a13')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a14')}}(USD)</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a15')}}(USD)</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a16')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 164px">
                                                <span class="font-12 color-gray">{{$t('po.a17')}}</span>
                                            </div>
                                            <div class="tableItem" style="justify-content: flex-end;text-align: right">
                                                <span class="font-12 color-gray">{{$t('po.a18')}}</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab4">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && history.history && history.history.list <= 0" :msg="$t('po.a19')"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.history ? history.history.list :[])" :key="'history'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" v-if="item.type === 1">{{$t('po.a1')}}</span>
                                                            <span class="color-red" v-if="item.type === 2">{{$t('po.a2')}}</span>
                                                            <span class="color-red" v-if="item.type === 3">{{$t('po.a3')}}</span>
                                                            <span class="color-red" v-if="item.type === 4">{{$t('po.a4')}}</span>
                                                            <span class="color-red" v-if="item.type === 5">{{$t('po.a5')}}</span>
                                                            <span class="color-red" v-if="item.type === 6">{{$t('po.a6')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  类型 -->
                                                    <div class="cellItem">
                                                        <span class="color-black" v-if="item.ttype === 1">{{$t('po.a20')}}</span>
                                                        <span class="color-black" v-if="item.ttype === 2">{{$t('po.a21')}}</span>
                                                        <span class="color-black" v-if="item.ttype === 3">{{$t('po.a22')}}</span>
                                                    </div>
                                                    <!--  成交均价/委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.status === 1 ? $util.thousandSeparator(item['t_price'],2):'0.00'}}/{{$util.thousandSeparator(item['price'],2)}}
                                                        </span>
                                                    </div>
                                                    <!--  成交数量/委托数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.status === 1 ? $util.float(item.amount):'0.00'}}/{{$util.float(item.amount)}}
                                                        </span>
                                                    </div>
                                                    <!--  盈亏 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.profit)}}</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.charge)}}</span>
                                                    </div>
                                                    <!--  状态 -->
                                                    <div class="cellItem">
                                                        <span class="color-black" v-if="item.status === 2">{{$t('po.a23')}}</span>
                                                        <span class="color-black" v-if="item.status === 1">{{$t('po.po.a24')}}</span>
                                                        <span class="color-black" v-if="item.status === 3">{{$t('po.a25')}}</span>
                                                    </div>
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  订单详情 -->
                                                    <div class="cellItem" style="justify-content: flex-start;text-align: left">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;orderInfoPopup = true">{{$t('po.a26')}}</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="5" :title="$t('po.a27')">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab5">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a28')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">{{$t('po.a29')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">{{$t('po.a30')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">{{$t('po.a31')}}</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">{{$t('po.a32')}}</span>
                                            </div>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted">{{$t('po.a33')}}</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        {{$t('po.a34')}}
                                                    </p>
                                                </template>
                                            </a-tooltip>
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">分润</span>-->
<!--                                            </div>-->
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted">{{$t('po.a35')}}</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                       {{$t('po.a36')}} 
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted"> {{$t('po.a37')}} </span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        {{$t('po.a38')}} 
                                                    </p>
                                                </template>
                                            </a-tooltip>
<!--                                            <a-tooltip type="subtip" position="bottom"-->
<!--                                                       background-color="var(&#45;&#45;tooltip-bg)">-->
<!--                                                <div class="tableItem">-->
<!--                                                    <span class="font-12 color-gray pointer dotted">仓位盈亏</span>-->
<!--                                                </div>-->
<!--                                                <template #content>-->
<!--                                                    <p class="font-12 color-always-white">-->
<!--                                                        仓位盈亏为仓位每次平仓的仓位盈亏之和，平仓盈亏根据-->
<!--                                                        平仓前的持仓均价和平仓均价计算得出-->
<!--                                                    </p>-->
<!--                                                </template>-->
<!--                                            </a-tooltip>-->
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">分摊</span>-->
<!--                                            </div>-->
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray"> {{$t('po.a39')}} </span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab5">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.profit ? history.profit.list : [])" :key="'profit'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coinBefore bg-red"></span>
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                        <a-tag color="var(--opacity-gray)">
                                                            <span class="color-black">{{item['margin_type'] === 1 ? 'all':'out'}}{{item['muilt']}}X</span>
                                                        </a-tag>
                                                    </div>
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <a-tag color="var(--opacity-sell)" v-if="item.direction === 1">
                                                            <span class="color-red">{{$t('po.a40')}}</span>
                                                        </a-tag>
                                                        <a-tag color="var(--opacity-buy)" v-else>
                                                            <span class="color-green">{{$t('po.a41')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  开仓均价 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['price'],current['decimals'])}}
                                                        </span>
                                                    </div>
                                                    <!--  平仓均价 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">
                                                            {{$util.float(item['t_price_3'] === null ? item['t_price_4']:item['t_price_3'])}}
                                                        </span>
                                                    </div>
                                                    <!--  仓位数量 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">{{$util.float(item.amount)}} {{item['symbol']}}</span>
                                                    </div>
                                                    <!--  已实盈亏 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span :class="{'color-red':item['profit']<=0,'color-green':item['profit']>0}">{{$util.thousandSeparator(item['profit'])}} USD</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-red">{{$util.thousandSeparator(item['charge'])}} USD</span>
                                                    </div>
                                                    <!--  资金费用 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">0.00 USD</span>
                                                    </div>
                                                    <!--  平仓时间 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">{{item['created_at']}}</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="6" :title="$t('po.a41')" v-if="false">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab6">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a42')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a43')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a44')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a45')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a46')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a47')}}</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">{{$t('po.a48')}}</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab6">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.profit ? history.profit : [])" :key="'profit'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USD</span>
                                                    </div>
                                                    <!--  开仓均价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.float(item['price'])}}
                                                        </span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)" v-if="item.direction === 2">
                                                            <span class="color-red">{{$t('po.a40')}}</span>
                                                        </a-tag>
                                                        <a-tag color="var(--opacity-buy)" v-else>
                                                            <span class="color-green">{{$t('po.a41')}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  成交数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item['amount'])}} {{item['symbol']}}</span>
                                                    </div>
                                                    <!--  角色 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">taker</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">0.0725 USD</span>
                                                    </div>
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">2023-11-28 10:28:26</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                </div>

                <!--     -->
                <div class="content_main_right">
                    <div class="content_main_right_top">
                        <div class="_hd flex align-center jus-bet">
                            <div class="flex align-center">
                                <div class="flex align-center jus-center pointer mr-20" @click="marginModel = true">
                                    <span class="font-14 color-gray mr-5">{{positionModel === 0 ? $t('s.a63'):$t('s.a64') }}</span>
                                    <icon-swap class="color-gray"/>
                                </div>
                                <div class="flex align-center jus-center pointer" @click="leverArm = true">
                                    <span class="font-14 color-gray mr-5">{{leverVal}}X</span>
                                    <icon-swap class="color-gray"/>
                                </div>
                            </div>
                            <img class="pointer" src="@/assets/images/calculator.svg" @click="calculator = true">
                        </div>
                        <div class="_mn">
                            <!-- 开仓/平仓-->
                            <div class="flex align-center jus-bet">
                                <div class="btn" :class="btnActive===0?'buyActive':''" @click="btnActive = 0">{{$t('po.a49')}}</div>
                                <div class="btn" :class="btnActive===1?'sellActive':''" @click="btnActive = 1">{{$t('po.a50')}}</div>
                            </div>
                            <!-- 模式-->
                            <div class="flex align-center jus-start mode_group">
                                <div class="pad-lr-10 pointer mode_item"
                                     :class="modeIndex === index ? 'modeAcitve' : ''" v-for="(item,index) in modeOption"
                                     :key="index"
                                     @click="modeIndex = index">
                                    <span class="font-14 color-black" :class="{'font-bold': modeIndex === index}">{{
                                            item
                                        }}</span>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet" v-if="btnActive===0">
                                <div class="flex align-center">
                                    <span class="font-12 color-gray mr-5">{{$t('po.a51')}}</span>
                                    <span class="font-14 color-primary">{{$util.thousandSeparator(balance.legal_balance)}} USD</span>
                                </div>
<!--                                <div class="flex align-center" v-else>-->
<!--                                    <span class="font-12 color-gray mr-5">可平</span>-->
<!--                                    <span class="font-14 color-primary">{{$util.thousandSeparator(balance.legal_balance)}} USD</span>-->
<!--                                </div>-->
                                <div class="transferIcon" @click="transfeShow = true">
                                    <icon-swap/>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 0">
                                <span class="font-12 color-gray">{{$t('po.a52')}}</span>
                                <a-input type="text" v-model="price" :placeholder="$t('po.a53')"/>
                                <span class="font-12 color-gray">USD</span>
                            </div>
                            <!--  -->
                            <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 2">
                                <a-tooltip background-color="var(--tooltip-bg)">
                                    <span class="font-12 color-gray dotted cursor">{{$t('po.a54')}}</span>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                           {{$t('po.a55')}} </p>
                                    </template>
                                </a-tooltip>
                                <a-input type="text" v-model="wprice" placeholder=""/>
                                <span class="font-12 color-gray">USD</span>
                            </div>
							
                            <div class="_row flex align-center jus-bet" type="select" v-if="modeIndex == 2">
                                <div class="_subbox flex align-center jus-bet">
                                    <div class="_subbox_left">
                                        <span class="font-12 color-gray">{{$t('po.a56')}}</span>
                                        <div class="flex align-center jus-end inputBox">
                                            <a-input class="mr-10" :disabled="limitType!==0" v-model="price" type="text" placeholder=""/>
                                            <span class="font-12 color-gray">USD</span>
                                        </div>
                                    </div>
									
                                    <a-dropdown :render-to-body="false" :popup-max-height="false">
                                        <div class="selectTag">
                                            <a-tooltip position="lb" background-color="var(--tooltip-bg)">
                                                <span class="color-black font-12 dotted mr-5">{{limitType === 0 ? $t('po.a59'):$t('s.a97')}}</span>
                                                <icon-caret-down/>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        {{$t('po.a57')}}（Limit
                                                        Order） {{$t('po.a58')}}
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                        </div>
                                        <template #content>
                                            <a-doption @click="limitType=0">{{$t('po.a59')}}</a-doption>
                                            <a-doption @click="limitType=1">{{$t('po.a60')}}</a-doption>
                                        </template>
                                    </a-dropdown>
                                </div>
                            </div>
                            <!-- 市价 -->
                            <div class="_row _row_box flex align-center jus-bet" type="select">
                                <span class="font-12 color-gray">{{$t('po.a61')}}</span>
                                <a-input type="text" v-model="amount" placeholder=""/>
                                <a-dropdown :render-to-body="false" :popup-max-height="false">
                                    <span class="font-12 color-gray flex align-center pointer"><b
                                            class="mr-5 color-black">{{amounType===0?current['symbol']:'USD'}}</b> <icon-caret-down/></span>
                                    <template #content>
                                        <template v-show="btnActive===0">
                                            <a-doption @click="amounType=0">{{current['symbol']}}</a-doption>
                                            <a-doption @click="amounType=1">USD</a-doption>
                                        </template>
                                    </template>
                                </a-dropdown>
                            </div>
                            <p class="tipText" v-if="isLogin && balance.legal_balance <= current['min_amount']">{{$t('po.a62')}}</p>
                            <!--滑杆-->
                            <div class="_row">
                                <a-slider @change="sliderChange" v-model="rate" :default-value="rate" :max="100" :marks="marks"/>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <div class="flex align-center pointer">
                                    <a-checkbox value="1">
                                        <span class="font-14 color-gray">{{$t('po.a63')}} </span>
                                    </a-checkbox>
                                    <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill class="color-gray pointer"/>
                                        <template #content>
                                            <span class="font-12 color-always-white">{{$t('po.a64')}}</span>
                                        </template>
                                    </a-tooltip>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet" type="select" v-if="false">
                                <div class="flex align-center pointer">
                                    <a-checkbox class="mr-5" value="1"></a-checkbox>
                                    <a-dropdown :render-to-body="false" :popup-max-height="false">
                                        <span class="font-14 color-gray">{{$t('f.a1')}} <icon-caret-down/></span>
                                        <template #content>
                                            <a-doption>{{$t('f.a2')}}</a-doption>
                                            <a-doption>
                                                IOC
                                                <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                    <icon-question-circle-fill class="color-gray pointer"/>
                                                    <template #content>
                                                        <span
                                                                class="font-12 color-always-white">{{$t('f.a3')}}</span>
                                                    </template>
                                                </a-tooltip>
                                            </a-doption>
                                            <a-doption>
                                                FOK
                                                <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                    <icon-question-circle-fill class="color-gray pointer"/>
                                                    <template #content>
                                                        <span
                                                                class="font-12 color-always-white">{{$t('f.a4')}}</span>
                                                    </template>
                                                </a-tooltip>
                                            </a-doption>
                                        </template>
                                    </a-dropdown>
                                </div>
                            </div>
                            <!--            login-->
                            <div class="_row _row_box flex align-center jus-center" v-if="isLogin === false">
                                <span class="font-14 color-primary mr-5 pointer">{{$t('f.a5')}}</span>
                                <span class="font-14 color-gray mr-5">{{$t('f.a6')}}</span>
                                <span class="font-14 color-primary pointer">{{$t('f.a7')}}</span>
                            </div>
                            <div class="buttonGroup flex align-center jus-bet" v-else>
                                <template v-if="btnActive === 0">
                                    <a-button class="button" :loading="loading" mode="buy" @click="submitOrder(1)">
                                        {{$t('f.a8')}}
                                        <span class="font-12">（{{$t('f.a9')}}）</span>
                                    </a-button>
                                    <a-button class="button" :loading="loading" mode="sell" @click="submitOrder(2)">
                                         {{$t('f.a10')}}
                                        <span class="font-12">（ {{$t('f.a11')}}）</span>
                                    </a-button>
                                </template>
                                <template v-else>
                                    <a-button class="button" :loading="loading" mode="buy" @click="closePositionByForm(1)">
                                         {{$t('f.a12')}}
                                    </a-button>
                                    <a-button class="button" :loading="loading" mode="sell" @click="closePositionByForm(2)">
                                       {{$t('f.a13')}} 
                                    </a-button>
                                </template>
                            </div>

                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <template v-if="btnActive === 0">
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray"> {{$t('f.a14')}} </span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">{{$t('f.a15')}}</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{getTopAmount(1,current['new_price'])}} {{amounType === 0 ? current['symbol']:'U'}}</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">{{$t('f.a16')}}</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">{{$t('f.a17')}}</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{getTopAmount(2,current['new_price'])}} {{amounType === 0 ? current['symbol']:'U'}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">{{$t('f.a18')}}</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">{{$t('f.a19')}}</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getPositionAmount(1),8)}} {{current['symbol']}}</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">{{$t('f.a20')}}</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">{{$t('f.a21')}}</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getPositionAmount(2),8)}} {{current['symbol']}}</span>
                                    </div>
                                </template>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <template v-if="btnActive===0">
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">{{$t('f.a22')}}</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getMarginAmount(current['new_price']),2)}} USD</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">{{$t('f.a22')}}</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getMarginAmount(current['new_price']),2)}} USD</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">{{$t('f.a23')}}</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getStopProfit(amount)['long'],2)}} USD</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">{{$t('f.a23')}}</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getStopProfit(amount)['short'],2)}} USD</span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="content_main_right_bottom">
                        <div class="_hd _bottom_tb flex align-center jus-bet">
                            <span class="font-14 color-black">{{$t('f.a24')}}</span>
                            <span class="font-12 color-primary pointer" >{{$t('f.a25')}}</span>
<!--                            @click="toDetail"-->
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a26')}}</span>
                            <span class="font-14 color-black">{{current['symbol']}}USD</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a27')}}</span>
                            <span class="font-14 color-black">{{$t('quo.s12')}}</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a28')}}</span>
                            <span class="font-14 color-black">{{current['symbol']}}USD {{$t('f.a29')}}</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a30')}}</span>
                            <span class="font-14 color-black">USD</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a31')}}</span>
                            <span class="font-14 color-black">{{$util.float(current['face_value'])}} {{current['symbol']}}</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a32')}}</span>
                            <span class="font-14 color-black">0.01 USD</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a33')}}</span>
                            <span class="font-14 color-black">1.00 %</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">{{$t('f.a34')}}</span>
                            <span class="font-14 color-black">{{$t('f.a35')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer class="layoutFooter">
        </a-layout-footer>

        <!--      币种介绍-->
        <a-modal :closable="false"
                 align-center
                 width="960px"
                 modalClass="modal_main dark"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 :hide-title="true"
                 :footer="false"
                 :visible="aboutCoin">
            <div class="modal_main flex flex-row align-center jus-bet">
                <!--          close-->
                <div class="close" @click="aboutCoin=false">
                    <icon-close class="color-black pointer" size="24"/>
                </div>
                <!--          -->
                <div class="modal_main_left flex flex-column">
                    <p class="font-20 font-bold color-black">{{$t('f.a36')}}</p>
                    <p class="flex align-center font-20 font-bold color-black">
                        <img class="mr-5" :src="current['icon']">
                        {{ current['symbol'] }}/USD
                    </p>
                    <!--            排名-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a37')}}
                        <span class="color-black">No.{{currencyInfo['marketSort']}}</span>
                    </p>
                    <!--            市值-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a38')}}
                        <span class="color-black">{{currencyInfo['marketValue']}}</span>
                    </p>
                    <!--            市场占有率-->
                    <p type="line" class="font-14 color-gray flex align-center jus-bet">{{$t('f.a39')}}
                        <span class="color-black">{{currencyInfo['marketShare']}}</span>
                    </p>
                    <!--            流通量-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a40')}}
                        <span class="color-black">{{currencyInfo['Circulation']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            最大供应量-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a41')}}
                        <span class="color-black">{{currencyInfo['totalCurrency']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            流通率-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a42')}}
                        <span class="color-black">{{currencyInfo['CirculationRate']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            发行日期-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a43')}}
                        <span class="color-black">{{currencyInfo['issueDate']}}</span>
                    </p>
                    <!--            发行价格-->
                    <p type="line" class="font-14 color-gray flex align-center jus-bet">{{$t('f.a44')}}
                        <span class="color-black">{{currencyInfo['IssuePrice']}}</span>
                    </p>
                    <!--            历史最高价-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a45')}}
                        <span class="color-black">{{currencyInfo['hightPrice']}}</span>
                    </p>
                    <!--            历史最低价-->
                    <p class="font-14 color-gray flex align-center jus-bet">{{$t('f.a46')}}
                        <span class="color-black">{{currencyInfo['IssuePrice']}}</span>
                    </p>
                </div>
                <div class="modal_main_right flex flex-column">
                    <p class="font-20 font-bold color-black">{{$t('f.a47')}}{{ current['symbol'] }}</p>
                    <p class="font-14 color-black">
                        {{ current['symbol'] }} -
                        {{$t('f.a48')}}
                    </p>
                    <a-scrollbar type="embed" class="scroll">
                        {{currencyInfo['about']}}
                    </a-scrollbar>
                    <p class="font-20 font-bold color-black">{{$t('f.a49')}}</p>
                    <div class="flex align-center">
                        <a href="" class="font-14 color-primary pad-lr-10">{{$t('f.a50')}}</a>
                        <a href="" class="font-14 color-primary pad-lr-10">Github</a>
                        <a href="" class="font-14 color-primary pad-lr-10">{{$t('f.a51')}}</a>
                        <a href="" class="font-14 color-primary pad-lr-10">{{$t('f.a52')}}</a>
                    </div>
                    <p class="font-20 font-bold color-black">{{$t('f.a53')}}</p>
                    <div class="flex align-center">
                        <img class="img-small mr-12 pointer" src="@/assets/images/instagram.png">
                        <img class="img-small mr-12 pointer" src="@/assets/images/tw.png">
                        <img class="img-small mr-12 pointer" src="@/assets/images/facebook.png">
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      保证金模式 -->
        <a-modal :visible="marginModel"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="360px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox">
                <div class="popupBox-title">
                    <span class="title">{{current['symbol']}}{{$t('s.a62')}}</span>
                    <icon-close @click="marginModel = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <a-radio-group type="radio" @change="positionChange" size="large" class="radioGroup">
                        <a-radio class="radioLabel" :model-value="all" :value="0">
                            <span class="color-black">{{$t('s.a63')}}</span>
                        </a-radio>
                        <a-radio class="radioLabel" :model-value="signal" :value="1">
                            <span class="color-black">{{$t('s.a64')}}</span>
                        </a-radio>
                    </a-radio-group>
                    <p class="desc color-gray">{{$t('s.a65')}}
                        {{$t('s.a66')}}</p>
                    <div class="buttonGroup">
                        <a-button class="button" @click="marginModel = false">
                            <span>{{$t('common.cancel')}}</span>
                        </a-button>
                        <a-button class="button" @click="confirmPositionChange">
                            <span>{{$t('common.confirm')}}</span>
                        </a-button>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      杠杆 -->
        <a-modal :visible="leverArm"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="370px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox">
                <div class="popupBox-title">
                    <span class="title">{{current['symbol']}}USD {{$t('s.a67')}}</span>
                    <icon-close @click="leverArm = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <div class="inputBox">
                        <span>{{$t('s.a68')}}</span>
                        <a-input-number mode="embed" v-model="leverVal" :max="current['max_lever']" :min="current['min_lever']" :hide-button="false"
                                        placeholder="1"
                                        class="leverInput">
                            <template #plus>
                                <icon-plus/>
                            </template>
                            <template #minus>
                                <icon-minus/>
                            </template>
                        </a-input-number>
                    </div>
                    <div class="sliderBox">
                        <a-slider class="slider" v-model="leverVal" :default-value="1" :min="1" :max="125"
                                  :marks="leverSlider"/>
                        <p v-if="leverVal == 1">{{$t('s.a69')}}{{current['max_lever']}}X</p>
                    </div>
                    <ul class="tips">
                        <li class="tipsItem">
                            <span>{{$t('s.a70')}}：{{$util.thousandSeparator(current['max_amount'],current['decimals'])}}{{current['symbol']}}</span>
                        </li>
                        <li class="tipsItem">
                            <span>{{$t('s.a71')}}</span>
                        </li>
                        <li class="tipsItem">
                            <span>{{$t('s.a72')}}</span>
                        </li>
                    </ul>
                    <div class="buttonGroup">
                        <a-button class="button" @click="leverArm = false">
                            <span>{{$t('common.cancel')}}</span>
                        </a-button>
                        <a-button class="button" @click="setPositionLever">
                            <span>{{$t('common.confirm')}}</span>
                        </a-button>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      计算器 -->
        <a-modal :visible="calculator"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="672px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <view class="popupBox">
                <div class="popupBox-title">
                    <span class="title">{{$t('s.a73')}}</span>
                    <icon-close @click="calculator = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <div class="tabControl">
                        <div class="tabItem"
                             v-for="(tItem,index) in tabOption" :key="index"
                             :class="tabIndex === index ? 'tabItem-active' : ''"
                             @click="tabIndex = index">
                            <span class="color-always-white">{{ tItem }}</span>
                        </div>
                    </div>
                    <div class="tabContent">
                        <div class="tabContent-item">
                            <div class="shapedButton">
                                <div class="button" :class="buying === 1 ? 'buyActive' : ''" @click="buying = 1">
                                    <span class="color-always-white">{{$t('s.a74')}}</span>
                                </div>
                                <div class="button" :class="buying === 2 ? 'sellActive' : ''" @click="buying = 2">
                                    <span class="color-always-white">{{$t('s.a75')}}</span>
                                </div>
                            </div>
                            <div class="inputBox">
                                <span>{{$t('s.a68')}}</span>
                                <a-input-number mode="embed"
                                                v-model="cleverVal"
                                                :max="current['max_lever']"
                                                :min="1"
                                                :hide-button="false"
                                                placeholder="0"
                                                class="leverInput">
                                    <template #plus>
                                        <icon-plus/>
                                    </template>
                                    <template #minus>
                                        <icon-minus/>
                                    </template>
                                </a-input-number>
                            </div>
                            <div class="sliderBox">
                                <a-slider class="slider" v-model="cleverVal" :default-value="1" :min="1" :max="current['max_lever']"
                                          :marks="leverSlider"/>
                                <p> <span class="color-orange">{{$t('s.a76')}}{{$util.float(current['max_amount'])}} {{current['symbol']}}</span></p>
                            </div>
                            <div class="inputBox">
                                <span>{{$t('s.a77')}}</span>
                                <a-input class="input" v-model="cprice" placeholder="">
                                    <template #append>
                                        USD
                                    </template>
                                </a-input>
                            </div>
                            <div v-if="tabIndex === 0">
                                <div class="inputBox">
                                    <span>{{$t('s.a78')}}</span>
                                    <a-input class="input" v-model="cwprice" placeholder="">
                                        <template #append>
                                            USD
                                        </template>
                                    </a-input>
                                </div>
                                <div class="inputBox">
                                    <span>{{$t('s.a79')}}</span>
                                    <a-input class="input" v-model="camount" placeholder="">
                                        <template #append>
                                            {{current['symbol']}}
                                        </template>
                                    </a-input>
                                </div>
                            </div>

                            <div v-if="tabIndex === 1">
                                <div class="inputBox">
                                    <span>{{$t('s.a80')}}</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            %
                                        </template>
                                    </a-input>
                                </div>
                            </div>
                            <div v-if="tabIndex === 2">
                                <div class="inputBox">
                                    <span>{{$t('s.a81')}}</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            BTC
                                        </template>
                                    </a-input>
                                </div>
                                <div class="inputBox">
                                    <span>{{$t('s.a82')}}</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            USD
                                        </template>
                                    </a-input>
                                </div>
                            </div>
                            <div class="buttonGroup">
                                <a-button class="button" @click="leverArm = false">
                                    <span>{{$t('s.a83')}}</span>
                                </a-button>
                            </div>
                        </div>
                        <div class="tabContent-item">
                            <div class="rowCell title">
                                <span>{{$t('s.a84')}}</span>
                            </div>
                            <div v-if="tabIndex === 0">
                                <div class="rowCell">
                                    <span>{{$t('s.a85')}}</span>
                                    <span>{{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator(current['new_price'] * camount / cleverVal):'--'}} USD</span>
                                </div>
                                <div class="rowCell">
                                    <span>{{$t('s.a86')}}</span>
                                    <span>
                                        {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? cwprice - cprice : cprice - cwprice) * camount) : '--'}}
                                    </span>
                                </div>
                                <div class="rowCell">
                                    <span>{{$t('s.a87')}}</span>
                                    <span>
                                        {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator(((buying === 1 ? cwprice - cprice : cprice - cwprice) * camount) / (current['new_price'] * camount / cleverVal) * 100,2):'--' }}%
                                    </span>
                                </div>
                            </div>
                            <div class="rowCell" v-if="tabIndex === 1">
                                <span>{{$t('s.a88')}}</span>
                                <span>
                                    {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? cprice + (current['new_price'] * camount / cleverVal) / camount : cprice - (current['new_price'] * camount / cleverVal) / camount)):'--'}} USD
                                </span>
                            </div>
                            <div class="rowCell" v-if="tabIndex === 2">
                                <span>{{$t('s.a89')}}</span>
                                <span>
                                    {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? parseFloat(cprice) + parseFloat((current['new_price'] * camount / cleverVal) / camount) : parseFloat(cprice) - (current['new_price'] * camount / cleverVal) / camount)):'--'}} USD
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </view>
        </a-modal>
        <!--    资金划转-->
        <funds-transfe :visible="transfeShow" @close="transfeShow=false" @success="getBalance"></funds-transfe>
        <!--    下单-->
        <a-modal :visible="placeOrder"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title" v-if="modeIndex===1">{{$t('s.a90')}}</span>
                <span class="title" v-if="modeIndex===0">{{$t('s.a91')}}</span>
                <span class="title" v-if="modeIndex===2">{{$t('s.a92')}}</span>
                <icon-close @click="placeOrder = false" size="20"/>
            </div>
            <div class="popupBox-content">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red">{{submitDirection === 1 ? $t('po.a40'):$t('po.a41')}}</span>
                    </a-tag>
                    <span class="title color-black">{{current['symbol']}}USD</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">{{positionModel === 0 ? $t('s.a63'):$t('s.a64')}}{{leverVal}}X</span>
                    </a-tag>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$util.getDateTimeStr()}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('per.s47')}}</span>
                    <span class="color-black">{{price === '' ? $t('po.a60'):$util.thousandSeparator(price,current['decimals'])}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('per.s48')}}</span>
                    <span class="color-black">{{$util.thousandSeparator(amount)}} {{amounType === 0 ? current['symbol']:'USD'}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('per.s59')}}</span>
                    <span class="color-black">{{getMarginAmount(current['new_price'])}} USD</span>
                </div>
                <div class="itemCell">
                    <a-checkbox v-model="checked">
                        <span class="color-gray">{{$t('s.a93')}}</span>
                    </a-checkbox>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="placeOrder = false;loading=false">
                        <span>{{$t('common.cancel')}}</span>
                    </a-button>
                    <a-button class="button" @click="submitOrder(submitDirection,false)">
                        <span>{{$t('common.confirm')}}</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
		
        <!--    平仓-->
        <a-modal :visible="closePotion"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">{{$t('per.s76')}}</span>
                <icon-close @click="closePotion = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">{{$t('po.a41')}}</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">{{$t('po.a40')}}</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USD</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? $t('s.a63'):$t('s.a64') }}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="tabControl closePotionTabs">
                    <div class="tabItem"
                         v-for="(tItem,index) in closePotionTab" :key="index"
                         :class="tabIndex === index ? 'tabItem-active' : ''"
                         @click="tabIndex = index">
                        <span class="color-black">{{ tItem }}</span>
                    </div>
                </div>
                <div class="inputBox">
                    <span>{{$t('s.a78')}}</span>
                    <a-input class="input" :disabled="tabIndex === 1" v-model="pprice" placeholder="">
                        <template #append>
                            USD
                        </template>
                    </a-input>
                </div>
                <div class="inputBox">
                    <span>{{$t('s.a94')}}</span>
                    <a-input class="input" v-model="pamount" placeholder="">
                        <template #append>
                            {{current['symbol']}}
                        </template>
                    </a-input>
                </div>
                <div class="sliderBox">
                    <a-slider class="slider" @change="psliderChange" :default-value="0" :min="0" :max="100"
                              :marks="marks"/>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('s.a95')}}</span>
                    <span class="color-black">
                        {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                    </span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('s.a96')}}</span>
                    <span class="color-red">{{getPositionPamount(current['new_price'])}} <span class="color-black">USD</span></span>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="closePotion = false">
                        <span>{{$t('common.cancel')}}</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="closePositions">
                        <span>{{$t('common.confirm')}}</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    闪电平仓-->
        <a-modal :visible="flashClose"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">{{$t('per.s77')}}</span>
                <icon-close @click="flashClose = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">{{$t('po.a41')}}</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">{{$t('po.a40')}}</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USD</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? $t('s.a63'):$t('s.a64') }}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('per.s47')}}</span>
                    <span class="color-black">{{$t('s.a97')}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$t('s.a79')}}</span>
                    <span class="color-black">{{$t('s.a98')}}
                        <span class="color-primary">{{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}}</span> {{current['symbol']}}</span>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="flashClose = false">
                        <span>{{$t('common.cancel')}}</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="lightClose">
                        <span>{{$t('common.confirm')}}</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    止盈止损-->
        <a-modal :visible="profitLossPop"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="840px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">{{$t('po.a63')}}</span>
                <icon-close @click="profitLossPop = false" size="24"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">{{$t('po.a41')}}</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">{{$t('po.a40')}}</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USD</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? $t('s.a63'):$t('s.a64') }}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="columnPirce">
                    <div class="itemCol">
                        <span class="color-gray">{{$t('per.s55')}}</span>
                        <span class="color-black">{{$util.thousandSeparator(position.position[pindex]['price'],2)}}</span>
                    </div>
                    <div class="itemCol">
                        <span class="color-gray">{{$t('per.s9')}}</span>
                        <span class="color-red">{{$util.thousandSeparator(current['new_price'],2)}}</span>
                    </div>
                    <div class="itemCol">
                        <span class="color-gray">{{$t('per.s57')}}</span>
                        <span class="color-black">{{$util.thousandSeparator(position.position[pindex]['stopPrice'],2)}}</span>
                    </div>
                </div>
                <div class="profit_loss">
                    <div class="item">
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="color-green mr-5">{{$t('po.a5')}}</span>
                                <span class="mr-5 dotted">{{$t('po.a54')}}</span>
                                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                    <icon-question-circle-fill size="16" class="color-gray"/>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                            {{$t('s.a99')}}
                                        </p>
                                    </template>
                                </a-tooltip>
                            </div>
                            <a-input class="input" v-model="wpwprice" placeholder="">
                                <template #append>
                                    USD
                                </template>
                            </a-input>
                        </div>
                        <div class="inputGroup">
                            <div class="inputBox">
                                <div class="inputLeft">
                                    <span class="mr-5 dotted">{{$t('per.s94')}}</span>
                                    <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill size="16" class="color-gray"/>
                                        <template #content>
                                            <p class="font-12 color-always-white">
                                                {{$t('s.a100')}}
                                            </p>
                                        </template>
                                    </a-tooltip>
                                </div>
                                <a-input class="input" v-model="wpprice" :disabled="wptype===0" :placeholder="wptype === 0 ? $t('s.a97'):''">
                                    <template #append>
                                        USD
                                    </template>
                                </a-input>
                            </div>
                            <a-dropdown type="modeList" :popup-max-height="false">
                                <div class="selectBox">
                                    <span class="color-black">{{wptype === 0 ? $t('po.a60'):$t('po.a59')}}</span>
                                    <icon-caret-down size="14" class="color-black"/>
                                </div>
                                <template #content>
                                    <a-doption @click="wptype=0">{{$t('po.a60')}}</a-doption>
                                    <a-doption @click="wptype=1">{{$t('po.a59')}}</a-doption>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="mr-5">{{$t('po.a61')}}</span>
                            </div>
                            <a-input class="input" v-model="wamount" placeholder="">
                                <template #append>
                                    {{current['symbol']}}
                                </template>
                            </a-input>
                        </div>
                        <span class="color-gray tipText">
                           {{$t('s.a101')}}  <span
                                class="color-orange">{{position.position[pindex]['amount']}} / {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                        </span>
                        </span>
                        <div class="sliderBox mt-5">
                            <a-slider class="slider" @change="lsliderChange" :default-value="0" :min="0" :max="100"
                                      :marks="marks"/>
                        </div>
                        <div class="buttonBox">
                            <a-button class="button" @click="profitLossPop = false">
                                <span>{{$t('common.cancel')}}</span>
                            </a-button>
                        </div>
                    </div>
                    <div class="item">
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="color-red mr-5"> {{$t('po.a6')}}</span>
                                <span class="mr-5 dotted">{{$t('per.s93')}}</span>
                                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                    <icon-question-circle-fill size="16" class="color-gray"/>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                            {{$t('s.a99')}}
                                        </p>
                                    </template>
                                </a-tooltip>
                            </div>
                            <a-input class="input" v-model="lwprice" placeholder="">
                                <template #append>
                                    USD
                                </template>
                            </a-input>
                        </div>
                        <div class="inputGroup">
                            <div class="inputBox">
                                <div class="inputLeft">
                                    <span class="mr-5 dotted">{{$t('per.s94')}}</span>
                                    <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill size="16" class="color-gray"/>
                                        <template #content>
                                            <p class="font-12 color-always-white">
                                               {{$t('s.a100')}} 
                                            </p>
                                        </template>
                                    </a-tooltip>
                                </div>
                                <a-input class="input" v-model="lprice" :disabled="lptype===0" :placeholder="lptype===0?$t('s.a97'):''">
                                    <template #append>
                                        USD
                                    </template>
                                </a-input>
                            </div>
                            <a-dropdown type="modeList" :popup-max-height="false">
                                <div class="selectBox">
                                    <span class="color-black">{{lptype===0?$t('s.a97'):$t('po.a59')}}</span>
                                    <icon-caret-down size="14" class="color-black"/>
                                </div>
                                <template #content>
                                    <a-doption @click="lptype=0">{{$t('s.a97')}}</a-doption>
                                    <a-doption @click="lptype=1">{{$t('po.a59')}}</a-doption>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="mr-5">{{$t('po.a61')}}</span>
                            </div>
                            <a-input class="input" v-model="lamount" placeholder="">
                                <template #append>
                                    {{current['symbol']}}
                                </template>
                            </a-input>
                        </div>
                        <span class="color-gray tipText">
                            {{$t('s.a101')}} <span
                                class="color-orange">{{position.position[pindex]['amount']}} / {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                        </span>
                        </span>
                        <div class="sliderBox mt-5">
                            <a-slider class="slider" @change="llstopChange" :default-value="0" :min="0" :max="100"
                                      :marks="marks"/>
                        </div>
                        <div class="buttonBox">
                            <a-button class="button" :loading="loading" @click="wlProfitSet">
                                <span>{{$t('common.confirm')}}</span>
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--    撤单-->
        <a-modal :visible="wdOrder"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-content">
                <div class="imageBox">
                    <img src="@/assets/images/wd_order_img.png" alt="">
                </div>
                <span class="wdContext"> {{$t('s.a103')}}</span>
                <div class="buttonGroup">
                    <a-button class="button" @click="wdOrder = false">
                        <span>{{$t('common.cancel')}}</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="cancelOrder">
                        <span>{{$t('common.confirm')}}</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    订单详情-->
        <a-modal :visible="orderInfoPopup"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="488px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title"> {{$t('s.a104')}}</span>
                <icon-close @click="orderInfoPopup = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="tabPanelIndex===4 && pindex !== false">
                <div class="itemCell oderItemCell">
                    <div class="itemCell_Left">
                        <a-tag color="var(--opacity-sell)">
                            <span class="color-red" v-if="history.history['list'][pindex].type === 1">{{$t('po.a1')}}</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 2">{{$t('po.a2')}}</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 3">{{$t('po.a3')}}</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 4">{{$t('po.a4')}}</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 5">{{$t('po.a5')}}</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 6">{{$t('po.a6')}}</span>
                        </a-tag>
                        <span class="title color-black">{{history.history['list'][pindex].symbol}}USD</span>
                        <a-tag color="var(--opacity-gray)">
                            <span class="color-black">{{$t('per.s11')}}</span>
                        </a-tag>
                    </div>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===2">{{$t('po.a23')}}</span>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===1">{{$t('po.a24')}}</span>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===3">{{$t('po.a25')}}</span>
                </div>
                <div class="itemCell">
                    <div class="itemCell_Left">
                        <span class="color-gray mr-10">{{history.history['list'][pindex].created_at}}</span>
                        <a-tag color="var(--opacity-gray)">
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===1">{{$t('s.a45')}}</span>
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===2">{{$t('s.a52')}}</span>
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===3">{{$t('s.a102')}}</span>
                        </a-tag>
                    </div>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">{{$t('per.s81')}}</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].t_price):'0.00'}}/{{$util.float(history.history['list'][pindex].price)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">{{$t('po.a13')}}(USD)</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].amount):'0.00'}}/{{$util.float(history.history['list'][pindex].amount)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">{{$t('po.a15')}}(USD)</span>
                    <span class="color-black">{{$util.float(history.history['list'][pindex].charge)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">{{$t('po.a14')}}(USD)</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].profit):'0.00'}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">{{$t('s.a105')}}</span>
                    <div class="itemCell_Right">
                        <span class="mr-5 color-black">{{history.history['list'][pindex].id}}</span>
                        <icon-copy size="20" class="color-gray"/>
                    </div>
                </div>
                <div class="cardBox" v-if="history.history['list'][pindex].status === 2">
                    <div class="itemCell">
                        <span class="title color-black">{{$t('s.a29')}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">{{$t('s.a36')}}</span>
                        <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].t_price) : '--'}}</span>
                    </div>
                    <div class="itemCell" v-if="history.history['list'][pindex].type <= 2">
                        <span class="color-gray">{{$t('s.a77')}}</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].price)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">{{$t('po.a45')}}({{history.history['list'][pindex].symbol}})</span>
                        <span class="color-black">{{$util.float(history.history['list'][pindex].amount)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">{{$t('po.a35')}}(USD)/{{$t('per.s80')}}</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].charge)}}/taker</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">{{$t('po.a14')}}(USD)</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].profit)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">{{$t('s.a106')}}</span>
                        <span class="color-black">{{history.history['list'][pindex].created_at}}</span>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--    设置-->
        <a-drawer :width="340"
                  :footer="false"
                  :header="false"
                  :drawer-style="{background: 'var(--background-primary)'}"
                  :visible="settingDrawer"
                  @ok="settingDrawer = false"
                  @cancel="settingDrawer = false"
                  unmountOnClose>
            <div class="settingDrawer">
                <div class="titleBox">
                    <span>{{$t('s.a107')}}</span>
                    <icon-close class="color-black cursor" size="20" @click="settingDrawer = false"/>
                </div>
                <div class="label">
                    <p class="color-gray mb-5"> {{$t('r.a1')}}</p>
                    <p class="color-primary mb-5">
                        <icon-exclamation-polygon-fill/>
                        {{$t('s.a108')}}
                    </p>
                    <a-radio-group class="radioGroup">
                        <a-radio value="1">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>{{$t('r.a2')}}</p>
                                        <p class="desc">{{$t('r.a3')}}</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="2">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>{{$t('r.a4')}}</p>
                                        <p class="desc">
                                            {{$t('r.a5')}}</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                    </a-radio-group>
                </div>
                <div class="label">
                    <p class="color-gray title dotted mb-5">
                         {{$t('r.a6')}}
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <icon-question-circle-fill class="color-gray cursor" size="14"/>
                            <template #content>
                                <p class="font-12 color-always-white">
                                    {{$t('r.a7')}}
                                </p>
                            </template>
                        </a-tooltip>
                    </p>
                    <a-radio-group class="radioGroup" mode="row">
                        <a-radio value="1">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>24H</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="2">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>{{$t('r.a8')}}</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="3">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>{{$t('r.a9')}}</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="4">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>{{$t('r.a10')}}</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                    </a-radio-group>
                </div>
                <div class="label">
                    <p class="color-gray title mb-5">
                        {{$t('r.a11')}}
                    </p>
                    <div class="flex align-center jus-bet">
                        <span> {{$t('r.a12')}}</span>
                        <a-switch/>
                    </div>
                </div>
            </div>
        </a-drawer>
    </a-layout>
</template>
<script>
import service from './service';

export default service;
</script>

<style lang="scss" scoped>
@import "./futures.scss";
</style>