<template>
    <a-layout class="categories">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content class="body">
            <div class="body-content">
                <div class="head">
                    <h1>{{ parentName ? parentName : '帮助中心' }}</h1>
                    <a-input class="search" placeholder="搜索问题、关键词、文章">
                        <template #prefix>
                            <icon-search size="16" class="color-gray"/>
                        </template>
                    </a-input>
                </div>
                <div class="container">
                    <div class="_left" v-if="parentName">
                        <a-menu :collapsed="collapsed"
                                accordion
                                :default-open-keys="['0']"
                                :default-selected-keys="['0_0']">
                            <a-sub-menu :title="item.title" v-for="(item,index) in menuList" :key="index"
                                        @click="menuChange(index)">
                                <a-menu-item v-for="(sitem,sindex) in item.subMenu" :key="index+'_'+sindex"
                                             @click="subMenuChange(sitem.id)">
                                    {{ sitem.name }}
                                </a-menu-item>
                            </a-sub-menu>
                        </a-menu>
                    </div>
                    <div class="_right">
                        <a-scrollbar class="scroll">
                            <a-breadcrumb>
                                <a-breadcrumb-item @click="$router.push('/')">ATIXSCE</a-breadcrumb-item>
                                <a-breadcrumb-item @click="$router.push('/help')">帮助中心</a-breadcrumb-item>
                                <a-breadcrumb-item>{{ parentName }}</a-breadcrumb-item>
                            </a-breadcrumb>
                            <div class="">
                                <div class="content_title">
                                    {{ content.title }}
                                </div>
                                <div class="content_time">
                                    {{ content.created_at }}
                                </div>
                                <div class="content_content" v-html="content.content">
                                </div>
<!--                                <div class="tagButton">-->
<!--                                    <span>ATIXSCE动态</span>-->
<!--                                </div>-->
                            </div>
<!--                            <div class="list">-->
<!--                                <p class="title">最新活动</p>-->
<!--                                <div class="list-con">-->
<!--                                    <div class="item" v-for="i in 30">-->
<!--                                        <p class="color-black">Bitget 关于恢复 ABT-ERC20ERC20ERC20ERC20 Bitget 关于恢复-->
<!--                                            ABT-ERC20 提币的公告Bitget 关于恢复 ABT-ERC20 提币的公告</p>-->
<!--                                        <p class="color-gray">-->
<!--                                            <a-tag color="var(&#45;&#45;opacity-gray)">-->
<!--                                                <span class="color-black">现货维护</span>-->
<!--                                            </a-tag>-->
<!--                                            2023-12-11 10:50-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </a-scrollbar>
                    </div>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import commonApi from '@/common/api/common';
import cmsApi from '@/common/api/cms';

export default {
    components: {
        Footer,
        PageNav
    },
    data() {
        return {
            id: 0,
            params: '',
            content: '',
            cateName:'',
            parentName:'',
            menuList: [{
                title: '',
                subMenu: []
            }]
        }
    },
    created() {
        this.id = this.$route.query.id
        this.params = this.$route.query.params

        if(this.params){
            this.get_info(this.params)
        }else{
            this.parent_id = this.$route.query.parent_id //当前分类上级id
            this.cate_id = this.$route.query.cate_id //当前分类id
            this.get_cate(this.parent_id,this.cate_id);
        }
    },
    methods: {
        menuChange(e) {
            // console.log(e)
        },
        async get_info(params) {
            let info = await cmsApi.info(params);
            console.log(info.data)
            this.content = info.data
        },
        async get_cate(parent_id,cate_id){
            //这里需要获取上级分类名称，下级分类名称，下级分类下的所有文章
            let catename1 = await cmsApi.get_catename(parent_id);
            this.parentName = catename1.data.name
            //console.log(catename1.data.name)//上级分类的名称
            let catename2 = await cmsApi.get_catename(cate_id);
            this.cateName = catename2.data.name
            this.menuList[0].title = this.cateName;
            //console.log(catename2.data)//当前分类的名称
            //再查这个分类下面所有的文章
            let artlist = await cmsApi.list(catename2.data.id);
            this.articleList = artlist.data.list //当前分类下的文章
            console.log(artlist.data.list)//当前分类的名称
            this.menuList[0].subMenu = this.articleList.map(article => {
                return {
                    name: article.title,
                    id: article.id
                };
            });
            // 将第一篇文章赋值给content以默认显示
            if (this.articleList.length > 0) {
                this.id = this.articleList[0]['id'];
                this.get_info(this.id)
            }
        },
        async subMenuChange(id) {
            //console.log(id)
            // 在点击时调用get_info方法，传递文章的id
            this.get_info(id);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "categories";
</style>