<template>
  <div id="BindBox" class="BingBox">
    <a-layout>
      <a-layout-header>
        <PageNav></PageNav>
      </a-layout-header>
      <a-layout-content>
        <div class="bindBox_content">
          <div class="bindBox_content_top">
            <img class="titleImage" src="@/assets/title.png" alt="">
            <!-- button -->
            <div class="buttonGroup">
              <div class="button buttonActive">USDT奖池</div>
              <div class="button">ATIXSCE奖池</div>
            </div>
            <!-- price -->
            <div class="price">
              <span class="total">总奖池:</span>
              <span class="amount">97,570</span>
              <span class="currency">USDT</span>
            </div>
            <!-- recentlyPrice -->
            <div class="recentlyPrice">
              <a-carousel>
                <a-carousel-item></a-carousel-item>
              </a-carousel>
            </div>
            <!-- boxTab -->
            <div class="boxTab">
              <div class="boxTabItem" :class="{ active: index == isActive }"
                   v-for="(boxItem, index) in boxGroup" :key="index" @click="mouseEnter(boxItem, index)">
                <img :src="boxItem.src" alt="">
                <span>{{ boxItem.name }}</span>
                <span>{{ boxItem.price }}</span>
              </div>
            </div>
            <!-- blindbox-content -->
            <div class="blindBoxContent">
              <div class="blindCenter">
                <div class="boxCenter">
                  <img :src="activeArr.src" alt="">
                </div>
                <img @click="leftChange()" class="arrowLeft" src="@/assets/arrowLeft.png" alt="">
                <img @click="rightChange()" class="arrowRight" src="@/assets/arrowRight.png" alt="">
                <div class="heightPrice">
                  <span>{{ activeArr.name }}</span>
                  <span>最高奖金</span>
                  <span>{{ activeArr.heightPrice }}</span>
                  <span>U</span>
                </div>
                <div class="balanceNoLogin">
                  点击
                  <span class="sp">登录</span>
                  /
                  <span class="sp">注册</span>
                </div>
              </div>
              <div class="openBtnContent">
                <div class="openOne openBox">
                  <p>开1个</p>
                  <p>需 {{ activeArr.openOne }} USDT</p>
                </div>
                <div class="openFive openBox">
                  <p>开5个</p>
                  <p>需 {{ activeArr.openFive }} USDT</p>
                </div>
              </div>
            </div>
          </div>
          <div class="blindbox_content_bottom">
            <div class="ruleRecord">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1" title="游戏规则">
                  <div class="rules">
                    <h3>
                      <strong>什么是暴富盲盒？</strong>
                    </h3>
                    <p>暴富盲盒是ATIXSCE
                      Exchange为丰富用户的投资体验上线的衍生品。用户可通过支付一定数量的加密货币开启盲盒，开启后100%中奖，中奖后将获得随机数量的加密货币奖金返还。盲盒奖池分为USDT与ATIXSCE两大奖池，奖池之间互相独立，用户可任选奖池参与。
                    </p>
                    <p></p>
                    <p><br></p>
                    <h3><strong>盲盒说明：</strong></h3>
                    <p>
                      盲盒种类共分六类，分别为钻石、铂金、金盒、银盒、铜盒、铁盒子。不同的盲盒，开启所需支付单价及奖金均不同。</p>
                    <p>若选择USDT奖池，每开启一个盲盒，花费金额与最高奖金如下：</p>
                    <p>
                      <span>钻石：支付128U，最高获得8888U；<br>铂金：支付88U，最高获得3888U；<br>金盒：支付38U，最高获得1288U；<br>银盒：支付18U，最高获得688U；<br>铜盒：支付8U，最高获得188U；</span>
                    </p>
                    <p><span>铁盒：支付1U，最高获得38U。</span><br></p>
                    <p><br></p>
                    <h3><strong>参与流程：</strong></h3>
                    <p>
                      第一步：在页面中选择奖池类型，用户可选择进入USDT奖池或ATIXSCE奖池；然后选择盲盒类型，页面中共多种盲盒可供选择；</p>
                    <p style="text-indent: 0pt;">
                      第二步：用户每次可选择打开1个盲盒或打开5个盲盒，盲盒支付金额从现货账户扣除，若现货账户余额不足，系统将进行提示，此时可进行资金划转或充值；</p>
                    <p style="text-indent: 0pt;">第三步：开启盲盒后获得随机数量奖金返还，该奖金将自动发放至现货账户。</p>
                    <p style="text-indent: 0pt;"></p>
                    <p style="text-indent: 0pt;"><br></p>
                    <p style="text-indent: 0pt;">※本活动最终解释权归ATIXSCE所有。</p>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2" title="盲盒记录">
                  Content of Tab Panel 2
                </a-tab-pane>
              </a-tabs>
            </div>
            <div class="win">
              <div class="tabs">
                <span>中奖名单</span>
              </div>
              <div class="winList">
                <ul>
                  <li v-for="i in 10" :key="i">
                    <span>恭喜</span>
                    <span class="user">jjajdjsa222***@live.com</span>
                    <span class="get">抽中了</span>
                    <span class="amount">17.11USDT</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue"
import Footer from "@/components/Footer/footer.vue";

export default {
  components: {
    PageNav,Footer
  },
  data() {
    return {
      isActive: 0,
      boxGroup: [{
        id: 0,
        src: require("@/assets/boxDiamond.png"),
        name: "钻石盒",
        price: "128 U",
        heightPrice: "8,888",
        openOne: "128",
        openFive: "640"
      }, {
        id: 1,
        src: require("@/assets/boxPlatinum.png"),
        name: "铂金盒",
        price: "88 U",
        heightPrice: "3,888",
        openOne: "88",
        openFive: "440"
      }, {
        id: 2,
        src: require("@/assets/boxj.png"),
        name: "金盒",
        price: "38 U",
        heightPrice: "1,288",
        openOne: "38",
        openFive: "190"
      }, {
        id: 3,
        src: require("@/assets/boxy.png"),
        name: "银盒",
        price: "18 U",
        heightPrice: "688",
        openOne: "18",
        openFive: "90"
      }, {
        id: 4,
        src: require("@/assets/boxt.png"),
        name: "铜盒",
        price: "8 U",
        heightPrice: "188",
        openOne: "8",
        openFive: "40"
      }, {
        id: 5,
        src: require("@/assets/boxLron.png"),
        name: "铁盒",
        price: "1 U",
        heightPrice: "38",
        openOne: "1",
        openFive: "5"
      }],
      activeArr: [],
      activeId: 0,
    }
  },
  mounted() {
    this.activeArr = this.boxGroup[0];
  },
  methods: {
    mouseEnter(boxItem, index) {
      console.log(boxItem);
      this.activeArr = boxItem;
      this.isActive = index;
    },
    leftChange() {
      if (this.isActive <= this.boxGroup.length - 1 && this.isActive != 0) {
        this.isActive--;
      }
      this.activeArr = this.boxGroup[this.isActive];
    },
    rightChange() {
      if (this.isActive <= this.boxGroup.length - 1 && this.isActive != 5) {
        this.isActive++;
      }
      this.activeArr = this.boxGroup[this.isActive];
    }


  }
}
</script>