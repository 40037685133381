<template>
    <a-layout class="newHome">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content>
            <!--    banner_swiper  -->
            <div class="banner">
                <swiper class="bannerSwiper"
                        :space-between="20"
                        :modules="modules"
                        :autoplay="{delay:5000}"
                        :navigation="{
                          nextEl: '.banner-next',
                          prevEl: '.banner-prev'
                        }"
                        :loop="true"
                        slides-per-view="1">
                    <swiper-slide class="swiperSlide" v-for="(item,index) in bannerSwiperOption" :key="index">
                        <div class="contentBox" :style="{'background-image': `url(${item.cover})`}">
                            <div class="contentBox-info">
                                <h1 class="title">{{ item.title }}</h1>
                                <p class="context">{{ item.desc }}</p>
                                <a-button class="button">{{$t('other.q1')}}</a-button>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <a-button class="banner-button banner-prev" @click="switchSwiper">
                    <icon-left />
                </a-button>
                <a-button class="banner-button banner-next" @click="switchSwiper">
                    <icon-right />
                </a-button>
            </div>
            <!--      notice-->
            <div class="notice">
                <div class="notice-swiper">
                    <swiper class="noticeTrade"
                            :space-between="20"
                            :navigation="{
                              nextEl: '.swiper-next',
                              prevEl: '.swiper-prev'
                            }"
                            :modules="modules"
                            :loop="false"
                            slides-per-view="4">
                        <swiper-slide class="tradeSlide" v-for="(item,index) in swiperGroup" :key="index">
                            <div class="card">
                                <img :src="item.cover" alt="">
                            </div>
                        </swiper-slide>
                    </swiper>
                    <a-button class="swiper-button swiper-prev">
                        <icon-left />
                    </a-button>
                    <a-button class="swiper-button swiper-next">
                        <icon-right />
                    </a-button>
                </div>
                <div class="noticeContent">
                    <div class="contentInfo">
                        <img :src="require(`@/assets/images/home/horn-${userTheme}.svg`)" alt="">
                        <span>{{ noticeGroup[0] ? noticeGroup[0]['name'] : '' }}</span>
                    </div>
                    <icon-right class="color-black cursor" size="16"/>
                </div>
            </div>
            <!--      containerWarp-->
            <div class="containerWarp">
                <div class="hotCoin">
                    <h1 class="title">{{$t('other.q3')}}</h1>
                    <div class="tab">
                        <div class="item"
                             v-for="(item, index) in tabOption"
                             :key="index" @click="switchCurrency(index)">
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <table class="table">
                        <tr class="tableHead">
                            <th v-for="(item,index) in tableColumns">
                                <span>{{ item }}</span>
                            </th>
                        </tr>
                        <tr class="tableCell" v-for="(item,index) in currencyList" :key="'currency'+item.id">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <img :src="item['icon']" alt="">
                                <span class="color-gray">
                  <strong class="color-black font-16">{{ item.symbol }}</strong>
                  /usdt
                </span>
                            </td>
                            <td>
                <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    {{ $util.thousandSeparator(item.new_price, item.decimals) }}
                </span>
                            </td>
                            <td>
                <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    +{{ $util.thousandSeparator(item.change_rate, 2) }}%
                </span>
                            </td>
                            <td>
                                <span class="color-black">{{ $util.bigNumberToString(item.quota) }}</span>
                            </td>
                            <td>
                                <span class="color-primary font-bold">{{$t('other.q4')}}</span>
                            </td>
                        </tr>
                    </table>
                    <!--<a-button class="seeAll" @click="$router.push('/rank')">{{$t('other.q5')}}
                        <icon-right/>
                    </a-button>-->
                    <div class="anmationCoin">
                        <div class="row">
                            <div class="item" v-for="(item,index) in allCurrency" :key="'s1'+item.id">
                                <img :src="item['icon']" alt="">
                                <span class="coin">{{ item['symbol'] }}</span>
                                <div class="info">
                                    <span>{{ $util.thousandSeparator(item.new_price, item.decimals) }}</span>
                                    <span
                                        :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    <icon-caret-down v-if="item.change_rate <= 0"/>
                      <icon-caret-up v-else/>
                      {{ $util.thousandSeparator(item.change_rate, 2) }}%
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item" v-for="(item,index) in currencyList" :key="'s2'+item.id">
                                <img :src="item['icon']" alt="">
                                <span class="coin">{{ item['symbol'] }}</span>
                                <div class="info">
                                    <span>{{ $util.thousandSeparator(item.new_price, item.decimals) }}</span>
                                    <span
                                        :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                  <icon-caret-down v-if="item.change_rate <= 0"/>
                      <icon-caret-up v-else/>
                      {{ $util.thousandSeparator(item.change_rate, 2) }}%
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copy">
                    <div class="copyOrder">
                        <div class="info">
                            <h1 class="title">{{$t('other.q6')}}</h1>
                            <span class="contxt">{{$t('other.q7')}}</span>
                            <div class="column">
                                <div class="item">
                                    <span>{{ IndexConfig['trade_user'] }}</span>
                                    <span>{{$t('other.q8')}}</span>
                                </div>
                                <div class="item">
                                    <span>{{ IndexConfig['copy_user'] }}</span>
                                    <span>{{$t('other.q9')}}</span>
                                </div>
                                <div class="item">
                                    <span>{{ IndexConfig['total_p'] }}</span>
                                    <span>{{$t('other.q10')}}</span>
                                </div>
                            </div>
                            <a-button class="button" @click="$router.push('/copyTraders')">
                                {{$t('other.q11')}}
                                <icon-right/>
                            </a-button>
                        </div>
                        <div class="bgImage">
                            <img :src="require(`@/assets/images/home/Copyhomebg_${userTheme}.png`)" alt="">
                        </div>
                    </div>
                    <div class="tradeList">
                        <div class="list">
                            <div class="item" v-for="i in 20">
                                <div class="top">
                                    <div class="imgBox">
                                        <img src="@/assets/images/user-image.png" alt="">
                                    </div>
                                    <div class="usInfo">
                                        <div class="usName">
                                            <span>{{ randName[i] ? randName[i] : 'CBUSER-TB130' + i }}</span>
                                            <!--                      <img src="" alt="">-->
                                        </div>
                                        <div class="tooltip">
                                            <span>@{{ randName[i] ? randName[i] : 'CBUSER-TB130' + i }}</span>
                                            <!--                      <span>-->
                                            <!--                        <icon-user-group/>999/1000-->
                                            <!--                      </span>-->
                                        </div>
                                    </div>
                                </div>
                                <p class="price color-green">
                                    +{{ parseFloat(Math.random(1000, 100000) * 1000).toFixed(2) }}%</p>
                                <div class="cell">
                                    <span class="color-gray"> {{$t('other.q12')}}</span>
                                    <span class="color-gray">30{{$t('other.q13')}}</span>
                                </div>
                                <div class="canvasBox">
                                    <canvas class="canvas"></canvas>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">{{$t('other.q14')}}</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 100000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">{{$t('other.q15')}}</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 1000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">AUM</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 10000000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">{{$t('other.q16')}}</span>
                                    <span
                                        class="color-black">{{
                                            parseFloat(Math.random(10000, 100000000) * 1000).toFixed(2)
                                        }}%</span>
                                </div>
                                <div class="bottom">
                                    <div class="imgGroup">
                                        <image src="https://static-dev.bbbtrade.net/coin/btc.png"></image>
                                        <image src="https://static-dev.bbbtrade.net/coin/eth.png"></image>
                                        <image src="https://static-dev.bbbtrade.net/coin/trx.png"></image>
                                    </div>
                                    <div class="right">
                                        <span><icon-swap/>{{
                                                parseFloat(Math.random(1000, 100000) * 100).toFixed(0)
                                            }}</span>
                                        <span><icon-archive/>{{
                                                parseFloat(Math.random(1000, 100000) * 100).toFixed(2)
                                            }}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exchange">
                    <div class="card">
                        <h1 class="title">{{$t('other.q17')}}</h1>
                        <p class="subTitle">{{$t('other.q18')}}</p>
                        <a href="" class="boxList">
                            <div class="boxItem">
                                <img src="@/assets/images/home/one_proof_white.png" alt="">
                                <p>{{$t('other.q19')}}</p>
                                <span>{{$t('other.q20')}}，{{ config['site_name'] }} {{$t('other.q21')}}</span>
                            </div>
                            <div class="boxItem">
                                <img src="@/assets/images/home/two_storage_white.png" alt="">
                                <p>{{$t('other.q22')}}</p>
                                <span>{{$t('other.q23')}}</span>
                            </div>
                            <div class="boxItem">
                                <img src="@/assets/images/home/three_protection_white.png" alt="">
                                <p>{{$t('other.q24')}}</p>
                                <span>{{$t('other.q25')}}</span>
                            </div>
                        </a>
                    </div>
                    <div class="sponsor" @mouseenter="messiHover = false" @mouseleave="messiHover = true">
                        <div class="info">
                            <p>{{ config['site_name'] }}</p>
                            <p>{{$t('other.q26')}}</p>
                        </div>
                        <div class="bgImage">
                            <img src="@/assets/images/home/messi-default-new-compress.png" alt="" v-if="messiHover">
                            <img src="@/assets/images/home/messi-hover-new-compress.png" alt="" v-else>
                        </div>
                    </div>
                    <div class="card">
                        <h1 class="title">{{$t('other.q27')}}</h1>
                        <p class="subTitle">{{$t('other.q28')}}</p>
                        <div class="download">
                            <img class="bgImage" src="@/assets/web-download-zh_CN.png" alt="">
                            <div class="download-right">
                                <div class="codeBox">
                                    <div class="codeImage">
                                        <!--<img :src="config['dpage']" alt="">-->
                                        <img src="@/assets/down.png" alt="">
                                    </div>
                                    <div class="downInfo">
                                        <p>{{$t('login.t19')}}</p>
                                        <p>iOS & Android apps</p>
                                    </div>
                                </div>
                                <div class="middel">
                                    <div class="item" @click="openurl(config['ios_url'])">
                                        <img src="@/assets/appStore.png" alt="">
                                        <p>App Store</p>
                                    </div>
                                    <div class="item" @click="openurl(config['download'])">
                                        <img src="@/assets/googleplay-light.png" alt="">
                                        <p>Google Play</p>
                                    </div>
                                    <div class="item" @click="openurl(config['download'])">
                                        <img src="@/assets/android-light.png" alt="">
                                        <p>APK</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="join">
                    <h1>{{$t('other.q29')}}</h1>
                    <p>{{ config['site_name'] }}{{$t('other.q30')}} {{$t('other.q31')}}</p>
                    <div class="column">
                        <div class="item">
                            <p>{{$t('other.q31')}}</p>
                            <span>{{$t('other.q33')}}</span>
                        </div>
                        <div class="item">
                            <p>{{ allCurrency.length }}+ </p>
                            <span>{{$t('other.q34')}}</span>
                        </div>
                        <div class="item">
                            <p>{{$t('other.q35')}}</p>
                            <span>{{$t('other.q36')}}</span>
                        </div>
                        <div class="item">
                            <p>{{$t('other.q37')}}</p>
                            <span>{{$t('other.q38')}}</span>
                        </div>
                    </div>
                    <a-button class="button">
                        {{$t('other.q39')}}
                    </a-button>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
	
	<div class="kf">
	   <a href="https://direct.lc.chat/15354186/" target="_blank" class="boxList"><img src="@/assets/kf.png" alt="" ></a>
	</div>
	
</template>
<script>
// import { methods } from "@arco-design/web-vue/es/_utils/date";
import service from './service';

export default service;
</script>
<style lang="scss" scoped>
@import "newHome.scss";
.kf{
	position: fixed;
	right: 10px; /* 距离右边距离 */
	bottom: 10px; /* 距离底部距离 */
}
.kf img{
	width:70px;
}
.logo_box {
    display: flex;
    align-content: center;
    line-height: 94px;
    padding: 0 60px;
}
</style>