import {createApp} from "vue";
import animated from "animate.css";
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import App from "./App.vue";
import "@arco-design/web-vue/dist/arco.css";
import "@/assets/fonts/PingFang_Regular.ttf";
import router from "./router";
import store from "./store";
import i18n from './lang'
import util from './common/util';


import "@/styles/index.scss"
import "@/styles/theme.scss"

// Import Swiper styles
import 'swiper/css';
import "swiper/css/bundle"

import GAuth from 'vue3-google-oauth2';
const gAuthOptions = {
    clientId: '506499663076-mma2r99uderglch5jit7svtcaap6aoq5.apps.googleusercontent.com',
    scope: 'email',
    prompt: 'consent',
    fetch_basic_profile: true
};

const app = createApp(App);
app.config.globalProperties.$util = util
app.config.globalProperties.$router = router
app.use(ArcoVue)
    .use(ArcoVueIcon)
    .use(animated)
    .use(store)
    .use(router)
    .use(i18n)
    .use(GAuth,gAuthOptions)
    .mount("#app");
  

