<!-- 高级认证 -->
<template>
    <div class="main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button @click="goBack">
                    <template #icon>
                        <icon-left />
                    </template>
                </a-button>
                <span>Lv.2 高级认证</span>
            </div>
            <div class="recommendInvite-content">
                <div style="width: 1200px;">
                    <a-steps :current="step">
                        <a-step description="填写基本信息">第1步</a-step>
                        <a-step description="上传证件照">第2步</a-step>
                        <a-step description="上传手持证件照">第3步</a-step>
                    </a-steps>
                </div>
                <div class="box" v-if="step == 1">
                    <a-col :span="24">
                        <a-alert type="warning"
                            :style="{ color: '#ff9e2d', backgroundColor: '#2d271f' }">为了保障账户资金安全，需实名认证后才能进行相关操作，请您确保所提交的信息真实有效。</a-alert>
                    </a-col>
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>国家或地区</span></div>
                      <a-select v-model="value" :style="{ backgroundColor: '#1d2126', width: '400px', height: '44px' }" placeholder="请选择国家或地区" allow-search>
                        <a-option v-for="item of area" :value="item.cn" :key="item" :label="item.cn" />
                        
                      </a-select>
                    </div>
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>证件类型</span></div>
                        <a-select v-model="value2" size="large" :width="300"
                            :style="{ backgroundColor: '#1d2126', color: '#fff', height: '44px' }" placeholder="证件类型"
                            allow-clear long >
                                    <a-option>护照</a-option>
                                    <a-option>驾照</a-option>
                                    <a-option>其他合法证件</a-option>
                        </a-select>
                    </div>
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>姓名</span></div>
                        <a-input size="large" :width="300"
                            :style="{ backgroundColor: '#1d2126', color: '#fff', height: '44px' }" placeholder="请输入真实姓名"
                            allow-clear long />
                    </div>
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>证件号码</span></div>
                        <a-input size="large" :width="300"
                            :style="{ backgroundColor: '#1d2126', color: '#fff', height: '44px' }" placeholder="请输入真实证件号码"
                            allow-clear long />
                    </div>
                    <div class="recommendInvite-content-box">
                        <a-botton class="model-content-footer-btn2" @click="handleOk">下一步</a-botton>
                    </div>
                </div>
                <div class="box" v-if="step == 2">
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>身份证反面</span></div>
                        <!-- 上传start -->
                        <a-upload action="/" :fileList="file ? [file] : []" :show-file-list="false" @change="onChange"
                            @progress="onProgress">
                            <template #upload-button>
                                <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                    }`">
                                    <div class="box-upload custom-upload-avatar" v-if="file && file.url">
                                        <img :src="file.url" />
                                        <div class="arco-upload-list-picture-mask" style="line-height: 176px;">
                                            <IconEdit />
                                        </div>
                                        <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                            :percent="file.percent" type="circle" size="large" :style="{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                transform: 'translateX(-50%) translateY(-50%)',
                                            }" />
                                    </div>
                                    <div class="box box-ad" v-else>
                                        <div class="upload-z">
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </a-upload>
                        <!-- 上传end -->
                    </div>
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>身份证反面</span></div>
                        <!-- 上传start -->
                        <a-upload action="/" :fileList="file ? [file] : []" :show-file-list="false" @change="onChange"
                            @progress="onProgress">
                            <template #upload-button>
                                <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                    }`">
                                    <div class="box-upload custom-upload-avatar" v-if="file && file.url">
                                        <img :src="file.url" />
                                        <div class="arco-upload-list-picture-mask" style="line-height: 176px;">
                                            <IconEdit />
                                        </div>
                                        <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                            :percent="file.percent" type="circle" size="mini" :style="{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                transform: 'translateX(-50%) translateY(-50%)',
                                            }" />
                                    </div>
                                    <div class="box box-ad" v-else>
                                        <div class="upload-f">
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </a-upload>
                        <!-- 上传end -->
                    </div>
                    <div class="dsc">
                        <div class="title">上传要求</div>
                        <div class="ms"> 1.支持小于5M的JPG、JPEG或PNG格式图片。</div>
                        <div class="ms"> 2.证件照片要求清晰，必须能看清人像、证件号和姓名。</div>
                    </div>
                    <div class="recommendInvite-content-box-2">
                        <a-botton class="model-content-footer-btn3" @click="handleOk">上一步</a-botton>
                        <a-botton class="model-content-footer-btn2" @click="handleOk">下一步</a-botton>
                    </div>
                </div>
                <div class="box" v-if="step == 3">
                    <div class="recommendInvite-content-box">
                        <div class="title"><span>本人手持证件照+手写文案</span></div>
                        <!-- 上传start -->
                        <a-upload action="/" :fileList="file ? [file] : []" :show-file-list="false" @change="onChange"
                            @progress="onProgress">
                            <template #upload-button>
                                <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                    }`">
                                    <div class="box-upload custom-upload-avatar" v-if="file && file.url">
                                        <img :src="file.url" />
                                        <div class="arco-upload-list-picture-mask" style="line-height: 176px;">
                                            <IconEdit />
                                        </div>
                                        <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                            :percent="file.percent" type="circle" size="mini" :style="{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                transform: 'translateX(-50%) translateY(-50%)',
                                            }" />
                                    </div>
                                    <div class="box box-ad" v-else>
                                        <div class="upload-f">
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </a-upload>
                        <!-- 上传end -->
                    </div>
                    <div>
                        <div class="tips-title">手写文案内容（在干净白纸上书写）</div>
                        <div class="tips-title1">ATIXSCE + 申请当天日期</div>
                    </div>
                    <div class="dsc">
                        <div class="title">上传要求</div>
                        <div class="ms"> 1.照片需免冠，建议未化妆，手持证件人的五官清晰可鉴，完整露出手臂。</div>
                        <div class="ms"> 2.看镜头请保持图片清晰，本人手持证件+手写文案。</div>
                    </div>
                    <div class="recommendInvite-content-box-2">
                        <a-botton class="model-content-footer-btn3" @click="handleOk">上一步</a-botton>
                        <a-botton class="model-content-footer-btn2" @click="handleOk">提交</a-botton>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
  
<script>
import country from '@/common/area';
export default {
    components: {
    },
    data() {
        return {
            name: 'seniorAuth',
            step: 1,
            file: '',
            area: country.countryList,

        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },
        onChange(_, currentFile) {
            this.file = {
                ...currentFile,
            }
        },
        onProgress(currentFile) {
            this.file = currentFile
        }

    },
};
</script>
<style lang="scss" scoped>
@import "./seniorAuth.scss";
</style>
  