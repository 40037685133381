<template>
  <div class="onePurchase" id="onePurchase">
    <a-layout>
      <a-layout-header>
        <PageNav></PageNav>
      </a-layout-header>
      <a-layout-content>
        <div class="content_wrap">
          <div class="content_main">
            <div class="content_header"></div>
            <div class="content_center">
              <div class="tabBox">
                <swiper :nacigation="nacigation" :space-between="10" class="tabList" :loop="false"
                        :slides-per-view="slidesPer">
                  <swiper-slide class="tabItem" :class="atDfa === index ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="aciChange(index)">
                    <div class="_column" v-show="atDfa !== index">
                      <span class="label">{{ item.label }}</span>
                      <span class="txt">{{ item.stage }}</span>
                    </div>
                    <div class="_column">
                      <span class="title" v-show="atDfa === index">当前{{ item.stage }}</span>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="content_center_main">
                <div class="left">
                  <p class="title">TA们正在1元购</p>
                  <div class="runnings"></div>
                </div>
                <div class="game">
                  <div class="price">
                    <span>奖金</span>
                    <span>188</span>
                    <span>USDT</span>
                  </div>
                  <!-- process-main-->
                  <div class="process_main">
                    <a-progress class="process" :animation="true" :show-text="false" status='danger' :percent="0.6" />
                    <div class="process_num">
                      <span>开奖进度</span>
                      <div class="_flex">
                        <span>86</span>
                        <span>/188</span>
                      </div>
                    </div>
                  </div>
                  <!--rule-->
                  <div class="rule">
                    <span>玩法</span>
                    <span>规则</span>
                  </div>
                  <!--screen-main-->
                  <div class="screen_main">
                    <p class="once">1 USDT/注</p>
                    <div class="rounds">
                      <div class="rdItem active" v-for="item in roundsList">
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </div>
                  <!--pay-main-->
                  <div class="pay_main">
                    <div class="pays">
                      <div class="has-money">
                        <span>支付</span>
                      </div>
                      <div class="pay-money">
                        <span>1,000 ATIXSCE</span>
                      </div>
                      <div class="batch batch-left-top" :class="batchActive == 0 ? 'active' : ''" @click="batchActive = 0">
                          <span>1 注</span>
                      </div>
                      <div class="batch batch-left-bottom" :class="batchActive == 1 ? 'active' : ''" @click="batchActive = 1">
                        <span>5 注</span>
                      </div>
                      <div class="batch batch-right" :class="batchActive == 2 ? 'activeRight' : ''" @click="batchActive = 2">
                        <span>10 注</span>
                      </div>
                      <div class="batch batch-right-bottom" :class="batchActive == 3 ? 'activeRight' : ''" @click="batchActive = 3">
                        <span>20 注</span>
                      </div>
                    </div>
                  </div>

                  <!--userinfo-->
                  <div class="userinfo">
                      <div class="login">
                        <span>点击</span>
                        <span>登录/</span>
                        <span>注册</span>
                      </div>
                  </div>

                  <!--game-buttons-->
                  <div class="game-buttons">
                    <div class="button" :class="btnActive === 0 ? 'btn-active' : ''" @click="btnActive = 0" >
                      <span>正在进行中</span>
                    </div>
                    <div class="button" :class="btnActive === 1 ? 'btn-active' : ''" @click="btnActive = 1">
                      <span>我参与的</span>
                    </div>
                    <div class="button" :class="btnActive === 2 ? 'btn-active' : ''" @click="btnActive = 2">
                      <span>往期</span>
                    </div>
                  </div>
                </div>
                <div class="my-number">
                  <p class="title">75022期 我的号码</p>
                  <div class="number-list">
                    <div class="number-list-item">
                      <span class="sort">1</span>
                      <div class="rounds">
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                      </div>
                    </div>
                    <div class="number-list-item">
                      <span class="sort">1</span>
                      <div class="rounds">
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                      </div>
                    </div>
                    <div class="number-list-item">
                      <span class="sort">1</span>
                      <div class="rounds">
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                      </div>
                    </div>
                    <div class="number-list-item">
                      <span class="sort">1</span>
                      <div class="rounds">
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                      </div>
                    </div>
                    <div class="number-list-item">
                      <span class="sort">1</span>
                      <div class="rounds">
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                        <div class="_i"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import {Swiper, SwiperSlide} from "swiper/vue";

export  default {
  components: {Swiper, SwiperSlide, PageNav, Footer},
  data(){
    return {
      atDfa: 0,
      slidesPer: 5,
      tabList:[{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      },{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      },{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      },{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      },{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      },{
        label:"688,000 ATIXSCE 奖金",
        stage:"480261期"
      }],
      roundsList:["1","2","3","4","5","6","7"],
      batchActive:0,
      btnActive:0
    }
  },
  methods: {
    aciChange(index) {
      this.atDfa = index;
    }
  }
}
</script>