<template>
    <a-layout class="topup">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content>
            <div class="content">
                <div class="header">
                    <p class="title">{{$t('ass.z2')}}</p>
                    <div class="label">
                        <p>{{$t('ass.z18')}}</p>
                        <a-trigger position="bottom" :popup-translate="[0, -40]" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <coin-list :display="true"></coin-list>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>{{$t('ass.z19')}}</p>
                        <a-trigger position="bottom" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <div class="cell">
                                        <span class="color-black">Ethereum(ERC20)</span>
                                    </div>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>{{$t('ass.z20')}}</p>
                        <p class="rg_add">
                            0x737a7c795978ade93d3ac5863a88ef0f3a25d6de
                            <icon-copy/>
                        </p>
                        <div class="re_code">
                            <canvas></canvas>
                        </div>
                        <p>{{$t('ass.z21')}}</p>
                        <div class="tips">
                            <p>
                                <span>{{$t('ass.z22')}}</span>
                                <span>0.0001 USDT</span>
                            </p>
                            <p>
                                <span>{{$t('ass.z23')}}</span>
                                <span>{{$t('ass.z24')}}</span>
                            </p>
                            <p>
                                <span>{{$t('ass.z25')}}</span>
                                <span>{{$t('ass.z24')}}</span>
                            </p>
                        </div>
                        <div class="tips" type="tip">
                            <p>
                                <span>{{$t('ass.z26')}}</span>
                                {{$t('ass.z27')}}<br>
                                {{$t('ass.z28')}}<br>
                                {{$t('ass.z29')}}<br>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btm">
                    <p class="title"> {{$t('ass.z30')}}</p>
                    <div class="warp">
                        <a-scrollbar class="topup-list">
                            <div class="cell">
                                <span>{{$t('st.z1')}}</span>
                                <span>{{$t('st.z2')}}</span>
                                <span>{{$t('st.z3')}}</span>
                                <span>{{$t('st.z4')}}</span>
                                <span>{{$t('st.z5')}}</span>
                                <span>{{$t('st.z6')}}</span>
                                <span>{{$t('st.z7')}}</span>
                                <span>{{$t('st.z8')}}</span>
                            </div>
                            <div class="cell" v-for="i in 30">
                                <span>2023-09-14 14:50:01</span>
                                <span>
                                <img src="@/assets/images/coin/usdt.png" alt="">
                                USDT
                            </span>
                                <span>Tron(TRC20)</span>
                                <span>21.999925</span>
                                <span class="up_line">
                                <b>TJTE6HJZ...pH54r5Pj</b>
                                <icon-copy/>
                            </span>
                                <span class="up_line">
                                <b>d6ca082c...1e82c04a</b>
                                 <icon-copy/>
                            </span>
                                <span>6/6</span>
                                <span class="color-green">{{$t('st.z9')}}</span>
                            </div>
                        </a-scrollbar>
                    </div>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>

<style lang="scss" scoped>
@import "topUp";
</style>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import CoinList from "@/components/coinList/coinList.vue";

export default {
    components: {
        PageNav,
        Footer,
        CoinList
    },
    data() {
        return {
            coinVal: this.$t('st.z10')
        }
    }
}
</script>