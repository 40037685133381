<template>
    <a-layout id="register">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="registerBody">
            <div class="left">
                <div class="bgImage">
                    <img src="@/assets/images/login/rg-bg.png" alt="">
                </div>
            </div>
            <div class="right">
                <h1>{{ $t('login.t14') }}</h1>
                <a-tabs default-active-key="1" @change="loginType">
                    <a-tab-pane :title="$t('us.a41')" key="1">
                        <div class="label">
                            <p class="color-gray">{{$t('us.a41')}}</p>
                            <a-input class="input" v-model="phone" :placeholder="$t('us.a42')" @change="checkPhone">
                                <template #prefix>
                                    <a-trigger v-model:popup-visible="showCountry" position="bottom" :popup-translate="[170, 10]" trigger="click">
                                        <div class="area">
                                            <span class="area-num">{{ code }}</span>
                                            <icon-caret-down/>
                                        </div>
                                        <template #content>
                                            <div class="areaWarp">
                                                <a-input-search class="searchInput" @input="filterCountry" :placeholder="$t('us.a43')"></a-input-search>
                                                <a-scrollbar class="areaList">
                                                    <div class="areaItem"
                                                         :class="code == item.dialingCode ? 'areaSelected' : ''"
                                                         v-for="(item,index) in country" :key="index"
                                                         @click="countryOk(item)">
                                                        <span class="color-black">{{ item.dialingCode }}</span>
                                                    </div>
                                                </a-scrollbar>
                                            </div>
                                        </template>
                                    </a-trigger>
                                </template>
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
						
                        <div class="label">
                            <p class="color-gray">{{$t('us.a44')}}</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" :placeholder="$t('us.a45')" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a46')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a47')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a48')}}</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>

                    </a-tab-pane>
					
                    <a-tab-pane :title="$t('us.a13')" key="2">
                        <div class="label">
                            <p class="color-gray">{{$t('us.a13')}}</p>
                            <a-input class="input" v-model="email" :placeholder="$t('us.a14')" @change="checkEmail">
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="label">
                            <p class="color-gray">{{$t('us.a44')}}</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" :placeholder="$t('rn.a058')" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a46')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a47')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a48')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a49')}}</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>
                    </a-tab-pane>
                </a-tabs>
				
                <div class="label" v-show="showVcode">
                    <p class="color-gray">{{$t('us.a15')}}</p>
                    <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                               :unmount-on-close="false">
                        <a-input class="input" :placeholder="$t('us.a16')" v-model="verifyCode"/>
                    </a-trigger>
                </div>
				
				
                <a-button class="registerBtn" @click="forget">
                    {{ $t('rn.a046') }}
                </a-button>
               
			   
                <div class="row">
                    <div class="item">
                        <span class="color-black"> {{$t('us.a53')}}<strong class="color-primary" @click="$router.push('/login')">{{$t('us.a54')}}</strong> </span>
                    </div>
                </div>
				
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import common from '@/common/api/common.js';
import country from '../../common/area';
import util from "@/common/util";

let seconds = 300;
export default {
  name: "LoginView",
  components: {PageNav},
  data() {
    return {
      country: country.countryList,
      code: '+886',
      showCountry: false,
      phone: '',
	  password:'',
      is_phone: false,
      email: '',
      is_email: false,
      type: 1,
	  verifyText: this.$t('login.t42'),
      showVcode: false,
	  is_time:0,
      vcode: '',
	  verifyCode:'',
    }
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    backChange() {
      this.$router.push("/login");
    },
    loginType(k) {
      this.type = k
    },
    checkPhone() {
      this.is_phone = !util.validatePhoneNumber(this.code + this.phone)
    },
    checkEmail() {
      this.is_email = !util.validateEmail(this.email)
    },
    checkPassword() {
        let len = this.password.length
        this.is_password = !(len >= 8 && len <= 64)
    },
    checkVcode() {
        let len = this.vcode.length
        this.is_vcode = !(len >= 4 && len <= 8)
    },
	countryOk(e) {
	    this.code = e.dialingCode;
	    this.showCountry = false;
	},
	filterCountry(e) {
	    this.country = country.countryList.filter((item) => {
	        return item.dialingCode.includes(e) || item.enName.includes(e) || item.cnName.includes(e);
	    });
	},
	
	async getVcode() {
	    let _this = this;
	    let res
	    if (this.type === 1) {
	        this.checkPhone()
	        if (this.phone === '') {
	            this.$notification.info(this.$t('login.t10'));
	            return
	        }
	        res = await common.sendFindCode(this.phone,this.type, this.code)
	    } else {
	        this.checkEmail()
	        if (this.email === '') {
	            this.$notification.info(this.$t('login.t11'));
	            return
	        }
	        res = await common.sendFindCode(this.email,this.type, this.code)
	    }
	    if (res.success) {
	        this.showVcode = true
	        this.$message.success(this.$t('login.t39'));
	        let set = setInterval(() => {
	            this.verifyText = seconds + "S";
	            seconds--;
	            if (seconds <= 0) {
	                seconds = 300;
	                clearInterval(set);
	                this.verifyText = _this.$t('login.t42')
	            }
	        }, 1000);
	    } else {
	        this.showVcode = false
	        this.$notification.warning(res.message);
	    }
	},
	
	async forget() {
	    const registerInfo = {
	        password: this.password,
	        type: this.type,
	        vcode: this.verifyCode,
	        invite_code: ''
	    };
	    let res;
	    this.checkVcode()
	    this.checkPassword()
	    if (this.type === 1) {
	        this.checkPhone()
	        if (this.is_phone || this.is_password) {
	            return;
	        }
	        registerInfo['account'] = this.phone;
	        registerInfo['area'] = this.code.replace('+', '');
	        registerInfo['code'] = this.verifyCode;
	        registerInfo['password'] = this.password;
			//console.log(registerInfo);return false;
	        res = await common.findPwdSet(registerInfo)
	        util.setCache('login_phone', this.phone);
	    } else {
	        this.checkEmail()
	        if (this.is_email || this.is_password) {
	            return;
	        }
	        registerInfo['account'] = this.email;
			registerInfo['area'] = this.code.replace('+', '');
			registerInfo['code'] = this.verifyCode;
			registerInfo['password'] = this.password;
			//console.log(registerInfo);return false;
	        res = await common.findPwdSet(registerInfo)
	        util.setCache('login_email', this.email);
	    }
	    if (res.success) {
	        let form = {
	            token: res.data.token,
	            id: res.data.id,
	            nickname: ""
	        }
	        if (this.type === 1) {
	            form['phone'] = registerInfo['phone'];
	            form['email'] = '';
	        } else {
	            form['phone'] = '';
	            form['email'] = registerInfo['email'];
	        }
	        //this.userLogin(form);
	        this.$notification.success({
	            content: this.$t('login.t33'),
	            duration: 2000
	        });
	        //this.$router.push('/HomeView/personal')
	    } else {
	        this.$message.info({
	            content: res.message,
	            showIcon: false
	        })
	    }
	},
	
	
  }
}
</script>
