<!-- 修改登录密码 -->
<template>
    <div class="dark main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>{{ $t('rn.a056') }}</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{ $t('rn.a057') }}</span></div>
                    <a-input-password class="input" :placeholder="$t('rn.a060')" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{ $t('rn.a058') }}</span></div>
                    <a-input-password class="input" :placeholder="$t('rn.a061')" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{ $t('rn.a059') }}</span></div>
                    <a-input-password class="input" :placeholder="$t('rn.a062')" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm" @click="handleOk">{{ $t('rn.a063') }}</a-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'changePhone',
    components: {},
    data() {
        return {};
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },
    },
};
</script>
<style lang="scss" scoped>
@import "./changePassword.scss";

</style>