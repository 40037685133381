// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// 引入swiper模块
import {Navigation, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import commonApi from '/src/common/api/common';
import currencyApi from '/src/common/api/currency';

import {
    mapState,
    mapGetters,
    mapMutations
} from 'vuex';
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue"

var currencyDesc;
var currencyASC;
var currencyQuan;
var currencyList;

export default {
    components: {
        PageNav,
        Swiper,
        SwiperSlide,
        Footer
    },
    computed: {
        ...mapState(['config', 'userinfo', 'userTheme']),
    },
    data() {
        return {
            modules:[Navigation, Autoplay],
            messiHover: false,
            themeType: false,
            bannerSwiperOption: [], 
            tabOption:[this.$t('per.s3'),this.$t('per.s4'),this.$t('per.s5'),this.$t('per.s6')],
            tableColumns:[this.$t('per.s7'),this.$t('per.s8'),this.$t('per.s9'),this.$t('per.s10'),this.$t('per.s6')],
            IndexConfig: {},
            currencyList: [],
            swiperGroup:[],
            noticeGroup:[],
            allCurrency:[],
            tabActive: 0,
            randName:[
                "Thriving business","CBUSER-TO1231","The King of Trends","Super Trader","Get-richtogether","WinMore","Professional Bit","CBUSER-T1242"
            ]
        }
    },
    created() {
        this.getIndexConfig();
        this.getIndexActive();
        this.getIndexNotice();
        this.getIndexBanner();

        this.getCurrencyList();
    },
	
    methods: {
        // swiper
        onBannerSwiper() {

        },
        bsSlideChange(e) {
            console.log(e);
        },
        switchSwiper(){
            console.log(1);
        },
        switchCurrency(index){
            this.tabActive = index;
            if (index === 0) {
                this.currencyList = currencyList['currency'].slice(0, 10);
            }
            if (index === 1) {
                this.currencyList = currencyDesc;
            }
            if (index === 2) {
                this.currencyList = currencyASC;
            }
            if (index === 3) {
                this.currencyList = currencyQuan;
            }
        },
        getCurrencyList() {
            currencyApi.tradeCurrencyList().then(res => {
                this.currencyList = res.data[0]['currency'].slice(0, 10);
                currencyList = res.data[0];
                this.allCurrency = res.data[0]['currency'];
                currencyDesc = this.$util.sortDesc(res.data[0]['currency']).slice(0, 10);
                currencyASC = this.$util.sortAsc(res.data[0]['currency']).slice(0, 10);
                currencyQuan = this.$util.sortQuan(res.data[0]['currency']).slice(0, 10);
            });
        },
        /**
         * banner内容获取
         */
        getIndexBanner(){
            commonApi.getBanner("pc-banner").then(res=>{
				//console.log(res.data);
               this.bannerSwiperOption = res.data;
            });
        },
        getIndexConfig() {
            commonApi.config(11).then(res => {
                this.IndexConfig = res.data;
            });
        },
        /**
         * 获取活动信息列表
         */
        getIndexActive() {
            commonApi.getBanner('pc_active').then(res => {
				//console.log(res.data);
                this.swiperGroup = res.data;
            });
        },
        /**
         * 获取公告信息列表
         */
        getIndexNotice() {
            commonApi.getNotice("pc_notice").then(res => {
                this.noticeGroup = res.data;
            });
        },
        themeChange() {
            this.themeType = !this.themeType;
        },
        tabChange(item, index) {
            this.tabActive.name = item.name;
            this.tabActive.backgroundColor = item.backgroundColor;
            this.tabActive.activeIndex = index;
            this.tabActive.activeImage = item.backgroundImage;
            if (index === 0) {
                this.currencyList = currencyList['currency'].slice(0, 5);
            }
            if (index === 1) {
                this.currencyList = currencyDesc;
            }
            if (index === 2) {
                this.currencyList = currencyASC;
            }
            if (index === 3) {
                this.currencyList = currencyQuan;
            }
        },
        generateRandomString() {
            let result = '';
            const characters = 'abcdefghijklmnopqrstuvwxyz';
            const charactersLength = characters.length;
            for (let i = 0; i < 4; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        openurl(url) {
            window.open(url);
        },
        toLogin() {
            this.$router.push("/login")
        },
        toSport() {
            this.$router.push("/HomeView/spot")
        },
        eclogTabChange(index) {
            this.eclogActive = index;
        }
    }
}