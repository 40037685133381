<template>
    <a-layout class="personal">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content>
            <div class="personal-body">
                <div class="menu">
                    <a-scrollbar class="menu-scroll">
                        <div class="cell"
                             :class="activeKey === item.id ? 'cell-active' : ''"
                             v-for="item in menuList" :key="item.id"
                             @click="tabActive(item.id)">
                            <span>{{ item.text }}</span>
                        </div>
                    </a-scrollbar>
                </div>
                <div class="container-warp" v-if="activeKey === 1">
                    <template v-if="userManagement">
                        <a-scrollbar class="scroll">
                            <h1>
                                {{$t('per.s2')}}, {{ userNickname }}
                                <img src="@/assets/images/personal_img/gm-img.svg" alt="">
                            </h1>
                            <p class="cite">{{$t('per.s1')}}</p>
                            <div class="user-box">
                                <img v-if="userinfo.avatar" class="user-image" :src="userinfo.avatar" alt="">
                                <img v-else class="user-image" src="@/assets/images/personal_img/management/headImg.png"
                                     alt="">
                                <div class="user-info">
                                    <div class="row">
                                        <span class="name">{{ userNickname }}</span>
                                        <div class="_right">
                                            <div class="eye-box" @click="eyeShow = !eyeShow">
                                                <icon-eye/>
                                            </div>
                                            <a-button class="btn">{{$t('po.a65')}}</a-button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="uid">
                                            <span>UID：{{ userId }}</span>
                                            <icon-copy/>
                                        </div>
                                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                                            <div class="attest">
                                        <span>
                                            <img src="@/assets/images/personal_img/icon-verified-light.svg" alt="">
                                        </span>
                                                <span class="attest-text">
                                            {{$t('po.a66')}}
                                        </span>
                                            </div>
                                            <template #content>
                                                <span class="color-always-white font-14"> {{$t('po.a67')}}</span>
                                            </template>
                                        </a-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div class="content-box" >
                                <div class="_left">
                                    <!--        资产-->
                                    <div class="assets-card" v-show="false">
                                        <p class="title">{{$t('po.a68')}}</p>
                                        <p class="assets-num">0.00000000</p>
                                        <div class="_desc">
                                            <span>{{$t('po.a69')}}</span>
                                            <a-button class="button">{{$t('po.a70')}}</a-button>
                                        </div>
                                    </div>
                                    <div class="_row">
                                        <!--      安全设置-->
                                        <div class="card" v-for="(setting, index) in setting" :key="index">
                                            <div class="_top">
                                                <p class="title">{{ setting.name }}</p>
                                                <div class="tag">
                                                    <div class="point"
                                                         v-if="setting.ifBind ? setting.status.color = '#26c99b' : setting.status.color = '#ff9e2d'
                          " :style="{ backgroundColor: setting.status.color }"></div>
                                                    <span :class=" setting.ifBind ? 'color-green' : 'color-orange'">
                                                    {{
                                                            setting.ifBind ? setting.status.name = this.$t('po.a71') : setting.status.name = this.$t('po.a72')
                                                        }}
                                                </span>
                                                </div>
                                            </div>
                                            <div class="_desc" @click="ClickToBind(setting)">
                                                <p>{{ setting.name }}</p>
                                                <p>
                                                    {{ setting.des }}
                                                    <icon-arrow-right/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="_right"></div>
                            </div>
                        </a-scrollbar>
                    </template>
                    <template v-if="safetyRecord">
                        <safetyRecord @safet-event="goBack"></safetyRecord>
                    </template>
                    <template v-if="bindEmail">
                        <bindEmail @safet-event="goBack"></bindEmail>
                    </template>
                    <template v-if="changePhone">
                        <changePhone @safet-event="goBack"></changePhone>
                    </template>
                    <template v-if="bindGoogle">
                        <bindGoogle @safet-event="goBack"></bindGoogle>
                    </template>
                    <template v-if="changePassword">
                        <changePassword @safet-event="goBack"></changePassword>
                    </template>
                </div>

                <div class="container-warp" v-if="activeKey == 2">
                    <!-- 身份认证 -->
                    <idAuth></idAuth>
                </div>
                <div class="container-warp" v-if="activeKey == 3">
                    <!-- API -->
                    <apiManagement></apiManagement>
                </div>
                <div class="container-warp" v-if="activeKey == 4">
                    <!-- 邀请返佣 -->
                    <recommendInvite></recommendInvite>
                </div>
                <div class="container-warp" v-if="activeKey == 5">
                    <!-- 偏好设置 -->
                    <userSettings></userSettings>
                </div>
            </div>


        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
    <Model title="修改昵称" top="30" @close="visible = false" v-if="visible" :showCancelButton="true"
           :showConfirmButton="true" @handleCancel="handleCancel" @handleOk="handleOkA">
        <!-- 使用组件的插槽 -->
        <template #content>
            <div class="model-content"><span>昵称</span></div>
            <a-input size="large" :style="{backgroundColor:'#1d2126',color:'#fff',height: '44px'}"
                     placeholder="昵称" allow-clear long/>
        </template>
    </Model>
</template>
<script>
import service from './service';

export default service;
</script>
<style lang="scss" scoped>
@import "./userManagement.scss";
</style>

<style>
/* 这个颜色选择器有点小毛病 */
.arco-select-dropdown {
    border-color: #1d2126;
    background-color: #1d2126;
}
</style>
