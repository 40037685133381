<!-- 绑定谷歌认证 -->
<template>
    <div class="google-bind">
        <a-scrollbar class="google-con">
            <div class="title">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>{{$t('us.a25')}}</span>
            </div>
            <div class="content-warp">
                <div class="label">
                    <div class="label-title">
                        <span>1</span>
                        <span>{{$t('us.a26')}}</span>
                    </div>
                    <div class="label-con">
                        <p>{{$t('us.a27')}}</p>
                        <a-button class="btn">
                            <svg viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="9516" width="20" height="20">
                                <path
                                    d="M118.016 45.696c19.52-17.28 47.488-17.344 69.632-4.928 191.232 103.168 381.056 208.64 571.328 313.472-53.056 52.48-107.52 103.744-160.448 156.288l-2.432 2.176C439.68 354.368 276.48 202.24 118.016 45.696z"
                                    fill="#01F076" p-id="9517"></path>
                                <path
                                    d="M96.32 160.64c0.256-38.272-5.312-82.048 21.184-114.24C277.76 202.88 442.304 354.816 600.64 512.96 438.528 668.16 279.232 825.6 117.504 981.248c-15.36-23.68-20.672-54.848-21.44-82.048 0.512-246.208 0.128-492.416 0.32-738.624z"
                                    fill="#01CCFF" p-id="9518"></path>
                                <path
                                    d="M760.192 352.448c64.832 35.2 128.896 71.488 193.152 107.52 22.592 12.16 43.712 36.096 37.568 62.976-6.656 27.072-33.408 41.344-56.128 54.016-58.496 30.528-114.752 64.96-173.056 96-54.208-54.016-110.464-106.112-163.008-161.6 53.312-53.44 108.16-105.536 161.472-158.912z"
                                    fill="#FFD700" p-id="9519"></path>
                                <path
                                    d="M595.584 514.816l2.432-2.112c51.84 54.4 107.392 105.536 160.896 158.528-186.432 104.32-373.696 207.36-560.96 310.4-23.936 14.08-57.6 17.536-79.936-1.28 158.912-155.52 318.272-310.464 477.568-465.536z"
                                    fill="#F53349" p-id="9520"></path>
                            </svg>
                            Google Play
                        </a-button>
                        <p>{{$t('us.a28')}}</p>
                        <p>{{$t('us.a29')}}</p>
                        <div class="flex align-center">
                            <a-trigger position="top" :popup-translate="[0, -10]">
                                <a-button class="btn">
                                    <svg viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                        <path
                                            d="M616.9 243c46.1-56.3 35.8-117.8 30.7-122.9-5.1-5.1-71.7 5.1-117.8 66.6-41 56.3-41 117.8-35.8 122.9C499.2 314.7 565.7 309.6 616.9 243L616.9 243zM729.6 529.8c-5.1-81.9 71.7-133.1 87-143.4l0-5.1c0 0-66.6-81.9-163.8-76.8-61.4 5.1-92.2 35.8-133.1 35.8-51.2 0-97.3-35.8-153.6-35.8-46.1 0-179.2 41-189.4 215-10.2 174.1 112.6 337.9 158.7 368.6 46.1 30.7 76.8 20.5 128-5.1 25.6-15.4 107.5-20.5 153.6 10.2 56.3 20.5 138.2 5.1 230.4-199.7C837.1 693.6 734.7 668 729.6 529.8L729.6 529.8zM729.6 529.8"
                                            fill="var(--content-white)">
                                        </path>
                                    </svg>
                                    {{$t('us.a30')}}
                                </a-button>
                                <template #content>
                                    <div class="code-image">
                                        <canvas></canvas>
                                    </div>
                                </template>
                            </a-trigger>
                            <a-trigger position="top" :popup-translate="[0, -10]">
                                <a-button class="btn">
                                    <svg viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="11410" width="20" height="20">
                                        <path
                                            d="M391.428571 276q9.142857 0 15.714286-6.571429t6.571429-15.714286-6.571429-15.714286-15.714286-6.571429-15.428571 6.571429-6.285714 15.714286 6.285714 15.714286 15.428571 6.571429zm241.142857 0q9.142857 0 15.428571-6.571429t6.285714-15.714286-6.285714-15.714286-15.428571-6.571429-15.714286 6.571429-6.571429 15.714286 6.571429 15.714286 15.714286 6.571429zm-464 105.142857q24 0 41.142857 17.142857t17.142857 41.142857l0 245.714286q0 24.571429-16.857143 41.714286t-41.428571 17.142857-41.714286-17.142857-17.142857-41.714286l0-245.714286q0-24 17.142857-41.142857t41.714286-17.142857zm605.714286 10.857143l0 380.571429q0 26.285714-18.285714 44.571429t-44 18.285714l-42.857143 0 0 129.714286q0 24.571429-17.142857 41.714286t-41.714286 17.142857-41.714286-17.142857-17.142857-41.714286l0-129.714286-78.857143 0 0 129.714286q0 24.571429-17.142857 41.714286t-41.714286 17.142857q-24 0-41.142857-17.142857t-17.142857-41.714286l-0.571429-129.714286-42.285714 0q-26.285714 0-44.571429-18.285714t-18.285714-44.571429l0-380.571429 524.571429 0zm-132.571429-231.428571q61.142857 31.428571 97.714286 87.714286t36.571429 123.142857l-528.571429 0q0-66.857143 36.571429-123.142857t98.285714-87.714286l-40.571429-74.857143q-4-7.428571 2.857143-11.428571 7.428571-3.428571 11.428571 3.428571l41.142857 75.428571q54.285714-24 114.857143-24t114.857143 24l41.142857-75.428571q4-6.857143 11.428571-3.428571 6.857143 4 2.857143 11.428571zm272.571429 278.857143l0 245.714286q0 24.571429-17.142857 41.714286t-41.714286 17.142857q-24 0-41.142857-17.142857t-17.142857-41.714286l0-245.714286q0-24.571429 17.142857-41.428571t41.142857-16.857143q24.571429 0 41.714286 16.857143t17.142857 41.428571z"
                                            fill="var(--content-white)">
                                        </path>
                                    </svg>
                                     {{$t('us.a31')}}
                                </a-button>
                                <template #content>
                                    <div class="code-image">
                                        <canvas></canvas>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>
                    </div>
                </div>
                <div class="label">
                    <div class="label-title">
                        <span>2</span>
                        <span>{{$t('us.a32')}}</span>
                    </div>
                    <div class="label-con">
                        <p>{{$t('us.a33')}}
                            {{$t('us.a34')}}
                        </p>
                        <div class="code-box">
                            <canvas></canvas>
                            <div class="code-text">
                                <p>EB4WGCBTHCLVXZYQ</p>
                                <a-button class="copy">
                                    <icon-copy/>
                                     {{$t('us.a35')}}
                                </a-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="label">
                    <div class="label-title">
                        <span>3</span>
                        <span> {{$t('us.a36')}}</span>
                    </div>
                    <div class="label-con">
                        <p>{{$t('us.a37')}}</p>
                        <a-input class="input" :class="{'notVal': codeVal == ''}" v-model="codeVal"></a-input>
                        <p class="tip" v-if="codeVal == ''">{{$t('us.a38')}}</p>
                        <a-button class="confirm-button">{{$t('us.a39')}}</a-button>
                    </div>
                </div>
            </div>
        </a-scrollbar>
    </div>
</template>

<script>
export default {
    name: 'bindGoogle',
    components: {},
    data() {
        return {
            codeVal: ''
        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },
    },
};
</script>
<style lang="scss" scoped>
@import "./bindGoogle.scss";
</style>
  