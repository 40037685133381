<!-- 绑定邮箱 -->
<template>
    <div class="main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>{{$t('us.a12')}}</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{$t('us.a13')}}</span></div>
                    <a-input class="input" :placeholder="$t('us.a14')" v-model="email" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{$t('us.a15')}}</span></div>
                    <a-input class="input" :placeholder="$t('us.a16')" v-model="code" allow-clear long>
                        <template #suffix>
                            <span>{{$t('us.a17')}}</span>
                        </template>
                    </a-input>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm"
                              :disabled="email == '' || code == ''"
                              @click="handleOk">
                        {{$t('us.a18')}}
                    </a-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    name: 'bindEmail',
    data() {
        return {
            email: '',
            code: ''
        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },

    },
};
</script>
<style lang="scss" scoped>
@import "./bindEmail.scss";
</style>
  