<!-- API -->
<template>
    <div class="api-warp">
        <a-breadcrumb>
            <!--<a-breadcrumb-item>ATIXSCE</a-breadcrumb-item>-->
            <a-breadcrumb-item>{{ $t('son.a032') }}</a-breadcrumb-item>
            <a-breadcrumb-item>API keys</a-breadcrumb-item>
        </a-breadcrumb>
        <h1>API keys</h1>
        <div class="tips">
            <p>{{ $t('son.a033') }}</p>
            <p>
                {{ $t('son.a034') }}
            </p>
            <p>
                {{ $t('son.a035') }}
            </p>
        </div>
        <a-button class="button"
                  @click="visible = true">
            <icon-plus/>
            {{ $t('son.a036') }}
        </a-button>
        <a-modal :visible="visible"
                 :hide-title="true"
                 :footer="false">
            <div class="model">
                <div class="title">
                    <span>{{ $t('son.a036') }}</span>
                    <icon-close @click="visible = false"/>
                </div>
                <div class="input-label">
                    <p>{{ $t('son.a037') }}</p>
                    <a-input class="input" :placeholder="$t('son.a039')"></a-input>
                </div>
                <div class="input-label">
                    <p>Passphrase</p>
                    <a-input class="input" :placeholder="$t('son.a040')"></a-input>
                </div>
                <a-button class="next">{{ $t('son.a038') }}</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import notdate from "@/components/notdate/notdate.vue";

export default {
    components: {
        notdate,
    },
    data() {
        return {
            visible: false,
            setting: [
                {
                   name: this.$t('son.a016'),
                   des: this.$t('son.a017'),
                    icon: "idAuth",
                    status: {
                         name: this.$t('son.a018'),
                        color: "#ff9e2d",
                    },
                    btn: this.$t('son.a019'),
                    type: "primary",
                    ifBind: true,
                },
                {
                    name: this.$t('son.a020'),
                    des: this.$t('son.a021'),
                    icon: "idAuth",
                    status: {
                         name: this.$t('son.a018'),
                        color: "#ff9e2d",
                    },
                     btn: this.$t('son.a019'),
                    type: "primary",
                    ifBind: true,
                },
            ],
        };
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
@import "./apiMangement.scss";
</style>
