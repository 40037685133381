<template>
    <a-modal :visible="visible"
             :footer="false"
             :hide-title="true"
             :mask-closable="false"
             width="854px">
        <div class="up-content">
            <div class="titleBox">
                <span class="titleText">{{$t('ass.z3')}}</span>
                <icon-close @click="close" size="22"/>
            </div>
            <div class="container">
                <div class="_left">
                    <div class="label">
                        <p>{{$t('per.s8')}}</p>
                        <a-trigger position="bottom" v-model:popup-visible="coinShow" :popup-translate="[0, 10]">
                            <div class="select-button">
                                <span class="defalut" v-if="!current">{{$t('us.a73')}}</span>
                                <div class="coin" v-else>
                                    <img :src="current['icon']" alt="">
                                    <span>{{current['symbol']}}</span>
                                </div>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="coin-list">
                                    <div class="top">
                                        <a-input class="search" v-model="search" :placeholder="$t('us.a70')">
                                            <template #prefix>
                                                <icon-search/>
                                            </template>
                                        </a-input>
                                    </div>
                                    <a-scrollbar class="bottom">
                                        <div class="cell" v-for="(item,index) in (search !== '' ? coin.filter(item=>item.symbol!==null&&item.symbol.toLowerCase().includes(search.toLowerCase())):coin.filter(item=>item.symbol !== null))" :key="'coin'+item.id" @click="itemclick(item)">
                                            <img :src="item['icon']" alt="">
                                            <span>{{item['symbol']}}</span>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </template>
                        </a-trigger>
                        <div class="hot-coin">
                            <div class="item" v-for="(item,index) in coin.filter(item=>item.symbol !== null).slice(0,4)" :key="'q'+item.id" @click="itemclick(item)">
                                <img :src="item['icon']" alt="">
                                <span>{{item['symbol']}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="label">
                        <p>{{$t('us.a74')}}</p>
                        <a-trigger position="bottom" v-model:popup-visible="netShow" :popup-translate="[0, 10]">
                            <div class="select-button">
                                <span class="defalut">{{ net === false ? $t('us.a75') : netlist[net]['symbol'] }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="network-list">
                                    <a-scrollbar class="scroll">
                                        <div class="cell" v-for="(item,index) in netlist" :key="'net'+item.id" @click="net=index;netShow=false">
                                            <span>{{item['symbol']}}</span>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>{{$t('us.a76')}}</p>
                        <a-input class="label-input" v-model="token" :placeholder="$t('us.a76')">
                            <template #suffix>
                                <svg width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" xmlns="http://www.w3.org/2000/svg"
                                     class="cursor">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M6.7998 4.7998C5.69524 4.7998 4.7998 5.69524 4.7998 6.79981V7.2002H6C6.66274 7.2002 7.2 7.73745 7.2 8.4002C7.2 9.06294 6.66274 9.6002 6 9.6002H4.7998V10.8H6C6.66274 10.8 7.2 11.3373 7.2 12C7.2 12.6627 6.66274 13.2 6 13.2H4.7998V14.4004H6C6.66274 14.4004 7.2 14.9376 7.2 15.6004C7.2 16.2631 6.66274 16.8004 6 16.8004H4.7998V17.1998C4.7998 18.3044 5.69524 19.1998 6.79981 19.1998H17.1998C18.3044 19.1998 19.1998 18.3044 19.1998 17.1998V6.7998C19.1998 5.69524 18.3044 4.7998 17.1998 4.7998H6.7998Z"
                                          fill="var(--content-primary)">
                                    </path>
                                    <path d="M11 10H13" stroke="#161A1E" stroke-width="2" stroke-linecap="round"></path>
                                    <path d="M11 14H15.5" stroke="#161A1E" stroke-width="2"
                                          stroke-linecap="round"></path>
                                </svg>
                            </template>
                        </a-input>
                    </div>
                    <div class="label">
                        <p>{{$t('us.a77')}}</p>
                        <a-input class="label-input" v-model="amount" :placeholder="current ? $t('us.a78'):$t('us.a79')">
                            <template #suffix>
                                <span class="color-primary cursor" @click="amount = current['balance']">{{$t('us.a80')}}</span>
                            </template>
                        </a-input>
                        <div class="mt-20" style="width: 100%" v-if="token !== '' && amount !== '' && current && net !== false">
                            <p>{{$t('us.a81')}}</p>
                            <a-input class="label-input" v-model="verifyCode" :placeholder="codeType===''?$t('us.a82'):(codeType==='google'?$t('us.a83'):codeType==='email'?$t('us.a84'):$t('us.a85'))">
                                <template #suffix>
                                    <span class="color-primary cursor" @click="sendCode">{{codeType===''?$t('us.a86'):$t('us.a87')}}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="overage">
                            <p>
                                {{$t('us.a88')}}
                                <span>{{current ? $util.float(current['balance']):'--'}} {{current['symbol']}}</span>
                            </p>
                            <p>
                                 {{$t('us.a89')}}
                                <span>{{current ? $util.float(current['with_charge']):'--'}} {{current['symbol']}}</span>
                            </p>
                        </div>
                    </div>
                    <a-button class="confirm" @click="submit" :loading="loading" :disabled="token === '' || amount === '' || !current || net === false || verifyCode === ''">
                        {{$t('us.a90')}}
                    </a-button>
                </div>
                <div class="_right">
                    <div class="card">
                        <div class="_cell">
                            <span>{{$t('us.a91')}}</span>
                            <span>{{current ? $util.float(current['with_min']):'--'}} {{current['symbol']}}</span>
                        </div>
                        <div class="_cell">
                            <span>{{$t('us.a92')}}</span>
                            <span>{{current ? $util.float(current['with_max']):'--'}} {{current['symbol']}}</span>
                        </div>
<!--                        <div class="_cell">-->
<!--                            <span>单日提现限额</span>-->
<!--                            <span>{{current ? $util.float(current['with_max']):'&#45;&#45;'}} {{current['symbol']}}</span>-->
<!--                        </div>-->
<!--                        <div class="_cell">-->
<!--                            <span>24小时提现限额</span>-->
<!--                            <span>8,698.2994 BCH</span>-->
<!--                        </div>-->
                    </div>
                    <div class="tips">
                        <p>{{$t('us.a93')}}</p>
                        <span>{{$t('us.a94')}}</span>
                        <span>{{$t('us.a95')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import commonApi from '@/common/api/common';
import userApi from '@/common/api/user';

import {mapState,mapGetters} from "vuex";

export default {
    name: 'withdrawTrgget',
    props: {
        visible: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            rechargeNetwork: '',
            coin: [],
            netlist: [],
            net: false,
            current: false,
            token: "",
            amount: "",
            content: "",
            title: "",
            type: "",
            search:"",
            coinShow:false,
            netShow:false,
            loading : false,

            verifyCode:"",
            codeType:""
        }
    },
    computed:{
        ...mapGetters(['isLogin','userId'])
    },
    mounted() {
        console.log("with coin");
        this.getCurrency();
    },
    methods: {
        submit(){
            this.loading = true;
            commonApi.postWithdraw({
                amount: this.amount,
                network: this.netlist[this.net]['id'],
                currency_id: this.current['id'],
                address: this.token,
                code: this.verifyCode,
                type: this.codeType
            }).then(res=>{
                this.loading = false;
                if(res.code === 200){
                    this.$notification.info("提现申请审核中，请注意通知信息");
                    this.token = '';
                    this.amount = '';
                    this.verifyCode = '';
                    this.codeType = '';

                    this.current['balance'] = parseFloat(parseFloat(this.current['balance']) - parseFloat(this.amount)).toFixed(8);
                }else{
                    this.$notification.info(res.message);
                }
            }).catch((e)=>{
                this.loading = false;
                this.$notification.info(e);
            })
        },
        sendCode(){
            if(parseFloat(this.amount) < parseFloat(this.current['with_min']) || parseFloat(this.amount) > parseFloat(this.current['with_max'])){
                this.$notification.info("提币数量不符合限制");
                return false;
            }
            userApi.getUniCode().then(res=>{
               if(res.code === 200){
                   this.$notification.info("已发送验证码");
                    this.codeType = res.data.type;
               }else{
                   this.$notification.info(res.message);
               }
            });
        },
        async getCurrency() {
            let res = await commonApi.getAccountCurrency();
            this.coin = res.data;
            this.current = this.coin[0];
            this.getNetList(this.coin[0]['id']);
        },
        getNetList(currency_id) {
            commonApi.getNetList(currency_id).then(res => {
                this.netlist = res.data.list;
                this.net = 0;
            })
        },
        itemclick(e) {
            this.current = e;
            this.getNetList(e.id);
            this.coinShow = false;
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "withdraw";
</style>