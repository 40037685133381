<!-- 修改手机 -->
<template>
    <div class="main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>{{$t('us.a19')}}</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{$t('us.a20')}}</span></div>
                    <a-input class="input"
                             autocapitalize="off"
                             :placeholder="$t('us.a21')" allow-clear long>
                        <template #prefix>
                            <div class="area">
                                <span>+86</span>
                                <icon-caret-down />
                            </div>
                        </template>
                    </a-input>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{$t('us.a22')}}</span></div>
                    <a-input class="input"
                             autocapitalize="off"
                             :placeholder="$t('us.a16')" allow-clear long>
                        <template #suffix>
                            <span>{{$t('us.a17')}}</span>
                        </template>
                    </a-input>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>{{$t('us.a23')}}</span></div>
                    <a-input-password class="input"
                                      :placeholder="$t('us.a24')"
                                      allow-clear long>
                    </a-input-password>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm" @click="handleOk">{{$t('us.a18')}}</a-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            name: 'changePhone',
            options: [
                {
                    value: '1',
                    label: '111111231231231231',
                },
                {
                    value: '2',
                    label: '2',
                },
                {
                    value: '3',
                    label: '3',
                },
            ]

        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },

    },
};
</script>
<style lang="scss" scoped>
@import "./changePhone.scss";

</style>
<style>
.arco-select-dropdown .arco-select-option {
    --color-bg-popup: #1d2126;
    --color-text-1: #fff;
    --color-fill-2: #2a2f37;
    --color-bg-2: #2a2f37;

}

.arco-select-option-active {
    --color-fill-2: #2a2f37;
}

.arco-input-append {
    border: none;
}

.arco-input-wrapper.arco-input-focus {
    background-color: #1d2126;
}

.arco-input-wrapper:hover {
    background-color: #2a2f37;
}
</style>
