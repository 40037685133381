<template>
  <a-layout id="loginWarp">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content class="loginBody">
      <div class="loginLeft">
        <div class="qrPanel">
			
          <div class="qrPanel-code">
            <div class="codeBox">
              <div class="codeImage">
                <canvas id="qrcode" canvas-id="qrcode" width="180" height="180" style="width: 180px;height: 180px;"></canvas>
                <div class="codeMask" v-if="false">
                  <a-button class="flushed">{{$t('us.a55')}}</a-button>
                  <span class="color-always-white">{{$t('us.a56')}}</span>
                </div>
              </div>
              <div class="beforeImage">
                <img src="@/assets/images/login/qrcode-tip-en-light.png" alt="" v-if="userTheme === 'light'">
                <img class="darkImage" src="@/assets/images/login/qrcode-tip-en-dark.svg" alt="" v-else>
              </div>
            </div>
            <p class="font-24 color-black font-bold">{{$t('us.a57')}}</p>
            <p class="color-gray mt-10">{{$t('us.a58')}} <strong class="color-primary">{{config['site_name']}} App</strong> ，{{$t('us.a59')}}
            </p>
          </div>
		  
          <!--<div class="join" @click="openUrl">
            <img src="@/assets/images/login/tw.png" alt="">
            <div class="joinInfo">
              <p class="color-black font-bold font-16">马上加入{{config['site_name']}}电报群</p>
              <span class="font-12 color-gray">获得帮助，分享心得，并接受最新的币记新闻。</span>
            </div>
          </div>-->
        </div>
      </div>
      <div class="loginRight">
        <h1>{{$t('us.a60')}} {{config['site_name']}} </h1>
        <a-tabs default-active-key="1" @change="changeType">
          <a-tab-pane key="1" :title="$t('us.a61')">
            <div class="label">
              <p class="color-gray">{{$t('us.a41')}}</p>
              <a-input class="input" v-model="phone" :placeholder="$t('us.a42')" @change="checkPhone">
                <template #prefix>
                  <a-trigger v-model:popup-visible="showCountry" position="bottom" :hide="resetCountry" :popup-translate="[190, 10]" trigger="click">
                    <div class="area">
                      <span class="area-num">{{ code }}</span>
                      <icon-caret-down/>
                    </div>
                    <template #content>
                      <div class="areaWarp">
                        <a-input-search @input="filterCountry" class="searchInput" :placeholder="$t('us.a43')"></a-input-search>
                        <a-scrollbar class="areaList">
                          <div class="areaItem"
                               :class="code == item.dialingCode ? 'areaSelected' : ''"
                               v-for="(item,index) in country" :key="index" @click="countryOk(item)">
                            <!--<span class="color-black mr-10">{{ item.cnName }}</span>-->
                            <span class="color-black">{{ item.dialingCode }}</span>
                          </div>
                        </a-scrollbar>
                      </div>
                    </template>
                  </a-trigger>
                </template>
              </a-input>
            </div>
            <div class="label">
              <p class="color-gray">{{$t('us.a62')}}</p>
              <a-input-password class="input" :placeholder="$t('us.a63')" v-model="password"
                                @change="checkPassword"></a-input-password>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" :title="$t('us.a64')">
            <div class="label">
              <p class="color-gray">{{$t('us.a65')}}</p>
              <a-input class="input" v-model="email" :placeholder="$t('us.a66')" @change="checkEmail"></a-input>
            </div>
            <div class="label">
              <p class="color-gray">{{$t('us.a661')}}</p>
              <a-input-password class="input" :placeholder="$t('us.a63')" v-model="password"
                                @change="checkPassword"></a-input-password>
            </div>
          </a-tab-pane>
        </a-tabs>
        <a-button :loading="loading" class="login" @click="login">
          {{loginText}}
        </a-button>
        <div class="row">
          <div class="item" @click="toRegister()">
            <span class="color-black">{{$t('us.a67')}}<strong class="color-primary">{{$t('us.a68')}}</strong></span>
          </div>
          <div class="item" @click="toReset()">
            <span class="color-primary">{{$t('us.a69')}}</span>
          </div>
        </div>
        <!--<div class="other">
          <a-divider orientation="center">或通过以下方式登录</a-divider>
          <a-button class="googleButton" @click="googleLogin">
            <img src="@/assets/googleIcon.svg" alt="">
            通过 Google 继续
          </a-button>
        </div>-->
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import service from './service';
export default service;
// import PageNav from "@/components/PageNav/PageNav.vue";
// import common from '@/common/api/common.js';
// import country from '../../common/area';
// import util from "@/common/util";
// import {mapState, mapMutations} from 'vuex';
// import {Notification} from '@arco-design/web-vue';
//
// export default {
//   name: "LoginView",
//   components: {PageNav},
//   data() {
//     return {
//       country: country.countryList,
//       code: '+86',
//       showCountry: false,
//       phone: '',
//       is_phone: false,
//       email: '',
//       is_email: false,
//       password: '',
//       is_password: false,
//       type: 1
//     }
//   },
//   mounted() {
//     let code = this.$route.query['invaite-code']
//     if (code) {
//       this.$util.setCache('invaite-code', code);
//     }
//   },
//   computed: {
//     ...mapState(['config', 'userTheme'])
//   },
//   methods: {
//     ...mapMutations(['userLogin']),
//     loginType(k) {
//       this.type = k
//     },
//     checkPhone() {
//       this.is_phone = !util.validatePhoneNumber(this.code + this.phone)
//       if (this.is_phone == false) {
//         this.handleNotice(this.$t('login.t10'));
//       }
//     },
//     checkEmail() {
//       this.is_email = !util.validateEmail(this.email)
//       if (this.is_email == false) {
//         this.handleNotice(this.$t('login.t11'));
//       }
//     },
//     checkPassword() {
//       let len = this.password.length
//       this.is_password = !(len >= 8 && len <= 64)
//       if (this.is_password == false) {
//         this.handleNotice(this.$t('login.t12'));
//       }
//     },
//     login() {
//       const loginInfo = {
//         phone: this.phone,
//         email: this.email,
//         password: this.password,
//         type: this.type
//       };
//       if (this.type == 1) {
//         if (!this.phone || !this.password) {
//           return;
//         }
//         loginInfo['phone'] = this.phone;
//         loginInfo['code'] = this.code.replace('+', '');
//         util.setCache('login_phone', this.phone);
//       } else {
//         if (!this.email || !this.password) {
//           return;
//         }
//         loginInfo['email'] = this.email;
//         util.setCache('login_email', this.email);
//       }
//
//       common.login(loginInfo).then((res) => {
//         if (res.success) {
//           this.userLogin(res.data);
//           this.$message.info({
//             content: this.$t('login.t31'),
//             duration: 2000,
//             showIcon: false
//           })
//           this.$router.push('/HomeView/assets')
//         } else {
//           this.$message.info({
//             content: res.message,
//             showIcon: false
//           })
//         }
//       });
//     },
//     toRegister() {
//       this.$router.push("/register");
//     },
//     toReset() {
//       this.$router.push("/resetPass");
//     },
//     countryOk(e) {
//       this.code = e.dialingCode
//     },
//     handleNotice(content) {
//       Notification.error({
//         title: '提示',
//         content: content,
//         closable: true,
//         duration: 2000
//       })
//     },
//     filterCountry(e) {
//       this.country = country.countryList.filter((item) => {
//         return item.dialingCode.includes(e) || item.enName.includes(e) || item.cnName.includes(e);
//       });
//     },
//     resetCountry() {
//       this.country = country.countryList;
//     },
//   }
// }
</script>


<style>
.basic {
  padding: 10px;
  width: 400px;
  background-color: #1d2126;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.country-item {
  display: flex;
  justify-content: space-between;
  width: 380px;
  height: 44px;
  color: #fff;
  padding: 10px 0;
}
</style>