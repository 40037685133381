<template>
    <div class="main-box">
        <p class="title">{{ $t('nav.aqs6') }}</p>
        <a-tabs default-active-key="1" @change="tabChange">
            <a-tab-pane :title="$t('us.z28')" key="1">
                <div class="select-group">
                    <div class="select-label">
                        <p>{{$t('po.a48')}}</p>
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="['2023-01-01 00:00:00']"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z29')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z30')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z31')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z32')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p> {{$t('us.z33')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z34')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in stateOption">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z35')}}</p>
                        <a-button class="button" @click="reset">
                            {{$t('us.z36')}}
                        </a-button>
                    </div>
                </div>

                <div class="list" type="zj">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span> {{$t('us.z37')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z38')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z39')}}</span>
                            </div>
<!--                            <div class="item">-->
<!--                                <span>确认次数</span>-->
<!--                            </div>-->
                            <div class="item">
                                <span>{{$t('us.z40')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z41')}}</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'b'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
<!--                            <div class="item">-->
<!--                                <span>6/6</span>-->
<!--                            </div>-->
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span @click="modalShow = false">{{$t('us.z42')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="size" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :title="$t('us.z43')" key="2">
                <div class="select-group">
                    <div class="select-label">
                        <p>{{$t('us.z37')}}</p>
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z29')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z30')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z31')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z32')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span> {{$t('us.z37')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z38')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z40')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z39')}}</span>
                            </div>
                            <div class="item">
                                <span> {{$t('us.z33')}}</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'t'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ? $t('us.z44'):$t('us.z45')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="size" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :title="$t('us.z46')" key="3">
                <div class="select-group">
                    <div class="select-label">
                        <p>{{$t('us.z37')}}</p>
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z29')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z30')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z31')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z32')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>{{$t('us.z37')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z40')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z47')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z33')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z38')}}</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'c'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ?$t('us.z44'):$t('us.z45')}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="15" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :title="$t('us.z48')" key="4">
                <div class="select-group">
                    <div class="select-label">
                        <p>{{$t('us.z37')}}</p>
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z29')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z30')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z31')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z32')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>{{$t('us.z37')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z40')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z47')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z33')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z31')}}</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'f'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ? $t('us.z44'):$t('us.z45')}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="15" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :title="$t('us.z49')" key="5">
                <div class="select-group">
                    <div class="select-label">
                        <p>{{$t('us.z37')}}</p>
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z29')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z30')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>{{$t('us.z31')}}</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                {{$t('us.z32')}}
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>{{$t('us.z37')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z38')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z40')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z39')}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z33')}}</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'i'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount}}</span>
                            </div>
                            <div class="item">
                                <span>{{$t('us.z21')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination :total="total" :page-size="15" @change="paginate" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
        <a-modal :visible="modalShow"
                 :closable="false"
                 :hide-title="false"
                 :footer="false"
                 width="450px">
            <template #title>
                <div class="model-title">
                    <span>{{$t('us.z50')}}</span>
                    <icon-close @click="modalShow = false" />
                </div>
            </template>
            <div class="model-content">
                <div class="coin">
                    <img src="@/assets/images/coin/usdt.png" alt="">
                    <span>USDT</span>
                </div>
                <div class="cell">
                    <span>{{$t('us.t1')}}</span>
                    <span>2023-09-14 14:15:01</span>
                </div>
                <div class="cell">
                    <span>{{$t('us.t2')}}</span>
                    <span>21.059222</span>
                </div>
                <div class="cell">
                    <span>{{$t('us.t3')}}</span>
                    <span>6/6</span>
                </div>
                <div class="cell">
                    <span>{{$t('us.t4')}}</span>
                    <span>{{$t('us.t5')}}</span>
                </div>
                <div class="cell">
                    <span>{{$t('us.t6')}}</span>
                    <span>2023-09-14 14:50:01</span>
                </div>
                <div class="column">
                    <span>{{$t('us.t7')}}</span>
                    <span>TJTE6HJZtYVGYPaAGQhqAFDBRSpH54r5Pj</span>
                </div>
                <div class="column">
                    <span>{{$t('us.t8')}}</span>
                    <span>d6ca082cca9dfcccffd864908504c5f0b305037bfb89daf7efc853c81e82c04a</span>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<style lang="scss" scoped>
@import "capitalFlow";
</style>
<script>
import service from './service';
export default service;
</script>