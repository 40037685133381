import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
    components: {
        Footer,
        PageNav
    },
    data() {
        return {
            tabOptions: [this.$t('per.s4'), this.$t('per.s5'), this.$t('r.a21'), this.$t('r.a22')],
            tabIndex: 0,
            priceGroup: [this.$t('r.a23'), '<$0.5', '$0.5-1', '$1-10', '$10-100', '>$100'],
            market: [this.$t('r.a24'), '<100M', '100-1B', '1B-5B', '5B-10B', '>50B'],
            increase: [this.$t('r.a25'), this.$t('r.a26'),  this.$t('r.a27'),  this.$t('r.a28'),  this.$t('r.a29')],
            priceVal: '',
            marketVal: '',
            increaseVal: '',
            pricesort: 1,
            marketsort: 1,
            increasesort: 1
        }
    },
    created() {
        this.priceVal = this.priceGroup[0]
        this.marketVal = this.market[0]
        this.increaseVal = this.increase[0]
    },
    methods: {
        priceSort() {
            switch (this.pricesort) {
                case 1:
                    this.pricesort = 2;
                    break;
                case 2:
                    this.pricesort = 3;
                    break;
                case 3:
                    this.pricesort = 1;
                    break;
            }
        }
    }
}
