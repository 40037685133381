<template>
  <a-layout class="rank">
    <a-layout-header>
      <page-nav></page-nav>
    </a-layout-header>
    <a-layout-content>
      <div class="rank-body">
        <a-breadcrumb>
          <a-breadcrumb-item>ATIXSCE</a-breadcrumb-item>
          <a-breadcrumb-item> {{$t('r.a13')}}</a-breadcrumb-item>
          <a-breadcrumb-item>{{$t('r.a14')}}</a-breadcrumb-item>
        </a-breadcrumb>
        <p class="title">{{$t('r.a14')}}</p>
        <div class="tab-box">
          <div class="tabs">
            <div class="item"
                 :class="{'active': tabIndex == index}"
                 v-for="(item,index) in tabOptions" :key="index"
                 @click="tabIndex = index">
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="select-box">
            <a-dropdown :render-to-body="false" @select="priceVal = $event">
              <div class="down-button">
                <span>{{ priceVal }}</span>
                <icon-down class="color-black"/>
              </div>
              <template #content>
                <a-doption :value="item"
                           :class="{'doption-active': priceVal == item}"
                           v-for="(item,index) in priceGroup">
                  {{ item }}
                </a-doption>
              </template>
            </a-dropdown>
            <a-dropdown :render-to-body="false" @select="marketVal = $event">
              <div class="down-button">
                <span>{{ marketVal }}</span>
                <icon-down class="color-black"/>
              </div>
              <template #content>
                <a-doption :value="item"
                           :class="{'doption-active': marketVal == item}"
                           v-for="(item,index) in market">
                  {{ item }}
                </a-doption>
              </template>
            </a-dropdown>
            <a-dropdown :render-to-body="false" @select="increaseVal = $event">
              <div class="down-button">
                <span>{{ increaseVal }}</span>
                <icon-down class="color-black"/>
              </div>
              <template #content>
                <a-doption :value="item"
                           :class="{'doption-active': increaseVal == item}"
                           v-for="(item,index) in increase">
                  {{ item }}
                </a-doption>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="table">
          <div class="table-head">
            <div class="item">
              <span>#</span>
            </div>
            <div class="item">
              <span>{{$t('r.a15')}}</span>
            </div>
            <div class="item">
              <span class="cursor" @click="priceSort">
                {{$t('r.a16')}}
                 <svg t="1702287193329" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="18">
                  <path
                      d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                      :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                      class="selected">
                  </path>
                  <path
                      d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                      :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                      class="selected">
                  </path>
                </svg>
              </span>
            </div>
            <div class="item">
              <span class="cursor">
                {{$t('r.a17')}}
                <svg t="1702287193329" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="18">
                  <path
                      d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                      :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                      class="selected">
                  </path>
                  <path
                      d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                      :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                      class="selected">
                  </path>
                </svg>
              </span>
            </div>
            <div class="item">
              <span class="cursor">
                {{$t('r.a18')}}
               <svg t="1702287193329" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="18">
                  <path
                      d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                      :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                      class="selected">
                  </path>
                  <path
                      d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                      :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'" p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                      class="selected">
                  </path>
                </svg>
              </span>
            </div>
            <div class="item">
              <span>{{$t('r.a19')}}</span>
            </div>
          </div>
          <div class="table-container">
            <div class="table-cell" v-for="i in 10">
              <div class="item">
                <span>{{ i }}</span>
              </div>
              <div class="item">
                <img src="" alt="">
                <span>
                  LAB
                  <span>/ USDT</span>
                </span>
              </div>
              <div class="item">
                <span>0.0422</span>
              </div>
              <div class="item">
                <span>+46.02%</span>
              </div>
              <div class="item">
                <span>8.165M</span>
              </div>
              <div class="item">
                <span>{{$t('r.a20')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <a-pagination :total="50"/>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import service from "./service"

export default service;
</script>
<style lang="scss" scoped>
@import "rank";
</style>