<template>
  <div class="notlog flex flex-column align-center jus-center dark">
    <img src="@/assets/images/pic-table-unlogin.png">
    <div class="flex flex-row align-center jus-center">
      <span class="font-14 color-gray"><b class="color-blue underline pointer" @click="$router.push('login')">{{$t('r.a30')}}</b> {{$t('r.a31')}} <b class="color-blue underline pointer" @click="$router.push('register')">{{$t('r.a32')}}</b> {{$t('r.a33')}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: 'notlog',
    }
  }
}
</script>
<style lang="scss" scoped>
.notlog {
  width: inherit;
  height: inherit;

  img {
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
  }
}
</style>