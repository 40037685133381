<template>
    <a-layout id="register">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="registerBody">
            <div class="left">
                <div class="bgImage">
                    <img src="@/assets/images/login/rg-bg.png" alt="">
                </div>
                <!--<a-button class="join" @click="openUrl">
                    <img src="@/assets/images/login/tw.png" alt="">
                    <div class="joinInfo">
                        <p class="color-black font-bold font-16">马上加入{{config.site_name}}电报群</p>
                        <span class="font-12 color-gray">获得帮助，分享心得，并接受最新的币记新闻。</span>
                    </div>
                </a-button>-->
            </div>
            <div class="right">
                <h1>{{$t('us.a40')}} {{config['site_name']}} </h1>
                <a-tabs default-active-key="1" @change="changeType">
                    <a-tab-pane :title="$t('us.a41')" key="1">
                        <div class="label">
                            <p class="color-gray">{{$t('us.a41')}}</p>
                            <a-input class="input" v-model="phone" :placeholder="$t('us.a42')" @change="checkPhone">
                                <template #prefix>
                                    <a-trigger v-model:popup-visible="showCountry" position="bottom" :popup-translate="[170, 10]" trigger="click">
                                        <div class="area">
                                            <span class="area-num">{{ code }}</span>
                                            <icon-caret-down/>
                                        </div>
                                        <template #content>
                                            <div class="areaWarp">
                                                <a-input-search class="searchInput" @input="filterCountry" :placeholder="$t('us.a43')"></a-input-search>
                                                <a-scrollbar class="areaList">
                                                    <div class="areaItem"
                                                         :class="code == item.dialingCode ? 'areaSelected' : ''"
                                                         v-for="(item,index) in country" :key="index"
                                                         @click="countryOk(item)">
                                                        <!--<span class="color-black mr-10">{{ item.cnName }}</span>-->
                                                        <span class="color-black">{{ item.dialingCode }}</span>
                                                    </div>
                                                </a-scrollbar>
                                            </div>
                                        </template>
                                    </a-trigger>
                                </template>
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="label">
                            <p class="color-gray">{{$t('us.a44')}}</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" :placeholder="$t('us.a45')" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a46')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a47')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a48')}}</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>

                    </a-tab-pane>
                    <a-tab-pane :title="$t('us.a13')" key="2">
                        <div class="label">
                            <p class="color-gray">{{$t('us.a13')}}</p>
                            <a-input class="input" v-model="email" :placeholder="$t('us.a14')" @change="checkEmail">
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="label">
                            <p class="color-gray">{{$t('us.a44')}}</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" :placeholder="$t('us.a45')" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a46')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a47')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a48')}}</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>{{$t('us.a49')}}</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>
                    </a-tab-pane>
                </a-tabs>
                <div class="label" v-show="showVcode">
                    <p class="color-gray">{{$t('us.a15')}}</p>
                    <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                               :unmount-on-close="false">
                        <a-input class="input" :placeholder="$t('us.a16')" v-model="verifyCode"/>
                    </a-trigger>
                </div>
                <div class="label">
                    <p class="color-gray font-12 invaite_t" @click="invaiteShow = !invaiteShow">{{$t('us.a50')}}
                        <icon-down/>
                    </p>
                    <a-input class="input" v-model="invaiteCode" :placeholder="$t('us.a51')"
                             v-if="invaiteShow">
                    </a-input>
                </div>
                <a-checkbox class="ruleCheck" value="1" @change="(v)=>{agree=v;}">
                    {{ $t('login.t26') }} {{config['site_name']}}
                    <a href='#'>《{{ $t('login.t27') }}》</a>
                    {{ $t('login.t28') }}
                    <a href='#'>《{{ $t('login.t29') }}》</a>
                </a-checkbox>
                <a-button class="registerBtn" @click="login">
                    {{$t('us.a52')}}
                </a-button>
                <!--<div class="other">
                    <a-divider orientation="center">或通过以下方式登录</a-divider>
                    <a-button class="googleButton">
                        <img src="@/assets/googleIcon.svg" alt="">
                        通过 Google 继续
                    </a-button>
                </div>-->
                <div class="row">
                    <div class="item">
                        <span class="color-black"> {{$t('us.a53')}}<strong class="color-primary" @click="$router.push('/login')">{{$t('us.a54')}}</strong> </span>
                    </div>
                </div>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
import service from './service';
export default service;
</script>