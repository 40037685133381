import PageNav from "@/components/PageNav/PageNav.vue";
import common from '@/common/api/common.js';
import country from '../../common/area';
import util from "@/common/util";
import {mapState, mapMutations} from 'vuex';

let seconds = 300;
export default {
    name: "LoginView",
    components: {PageNav},
    data() {
        return {
            country: country.countryList,
            code: '+886',
            vcode: '',
            is_vcode: false,
            showCountry: false,
            phone: '',
            is_phone: false,
            email: '',
            is_email: false,
            password: '',
            is_password: false,
            invaiteCode: '',
            type: 1,
            agree: 0,
            pwdverify: 3,
            searchVal: "",
            showVcode: false,
            verifyCode: "",
            invaiteShow: true,
            verifyText: this.$t('login.t42')
        }
    },
    mounted() {
        let code = this.$route.query['invaite-code']
        if (code) {
            this.$util.setCache('invaite-code', code);
        }
    },
    computed: {
        ...mapState(['config'])
    },
    watch: {
        password(v) {
            this.pwdverify = this.checkPasswordT(v);
            console.log(this.pwdverify);
        }
    },
    methods: {
        ...mapMutations(['userLogin']),
        openUrl(){
            window.open("https://t.me/chenmaq");
        },
        changeType(e) {
            console.log(e);
            this.type = parseInt(e);
        },
        toLogin() {
            this.$router.push("/login");
        },
        loginType(k) {
            this.type = k
        },
        checkPhone() {
            this.is_phone = !util.validatePhoneNumber(this.code + this.phone)
        },
        checkEmail() {
            this.is_email = !util.validateEmail(this.email)
        },
        checkPassword() {
            let len = this.password.length
            this.is_password = !(len >= 8 && len <= 64)
        },
        checkVcode() {
            let len = this.vcode.length
            this.is_vcode = !(len >= 4 && len <= 8)
        },
        async getVcode() {
            let _this = this;
            let res
            if (this.type === 1) {
                this.checkPhone()
                if (this.phone === '') {
                    this.$notification.info(this.$t('login.t10'));
                    return
                }
                res = await common.sendPhoneVerifyCode(this.phone, this.code)
            } else {
                this.checkEmail()
                if (this.email === '') {
                    this.$notification.info(this.$t('login.t11'));
                    return
                }
                res = await common.sendEmailVerifyCode(this.email)
            }
            if (res.success) {
                this.showVcode = true
                this.$message.success(this.$t('login.t39'));
                let set = setInterval(() => {
                    this.verifyText = seconds + "S";
                    seconds--;
                    if (seconds <= 0) {
                        seconds = 300;
                        clearInterval(set);
                        this.verifyText = _this.$t('login.t42')
                    }
                }, 1000);
            } else {
                this.showVcode = false
                this.$notification.warning(res.message);
            }
        },
        async login() {
            if (!this.agree) {
                this.$message.error({
                    content: this.$t('login.t32'),
                    showIcon: false
                })
                return
            }
            const registerInfo = {
                password: this.password,
                type: this.type,
                vcode: this.verifyCode,
                invite_code: this.invaiteCode
            };
            let res;
            this.checkVcode()
            this.checkPassword()
            if (this.type === 1) {
                this.checkPhone()
                if (this.is_phone || this.is_password) {
                    return;
                }
                registerInfo['phone'] = this.phone;
                registerInfo['code'] = this.code.replace('+', '');
                res = await common.phoneRegister(registerInfo)
                util.setCache('login_phone', this.phone);
            } else {
                this.checkEmail()
                if (this.is_email || this.is_password) {
                    return;
                }
                registerInfo['email'] = this.email;
                res = await common.emailRegister(registerInfo)
                util.setCache('login_email', this.email);
            }
            if (res.success) {
                let form = {
                    token: res.data.token,
                    id: res.data.id,
                    nickname: ""
                }
                if (this.type === 1) {
                    form['phone'] = registerInfo['phone'];
                    form['email'] = '';
                } else {
                    form['phone'] = '';
                    form['email'] = registerInfo['email'];
                }
                this.userLogin(form);
                this.$notification.success({
                    content: this.$t('login.t33'),
                    duration: 2000
                });
                this.$router.push('/HomeView/personal')
            } else {
                this.$message.info({
                    content: res.message,
                    showIcon: false
                })
            }
        },
        toRegister() {
            this.$router.push("/register");
        },
        toReset() {
            this.$router.push("/resetPass");
        },
        countryOk(e) {
            this.code = e.dialingCode;
            this.showCountry = false;
        },
        filterCountry(e) {
            this.country = country.countryList.filter((item) => {
                return item.dialingCode.includes(e) || item.enName.includes(e) || item.cnName.includes(e);
            });
        },
        resetCountry() {
            this.country = country.countryList;
        },
        checkPasswordT(input) {
            // 判断字符串是否为 8 到 14 位字符
            if (/^.{8,14}$/.test(input)) {
                // 判断是否包含数字
                if (/\d/.test(input)) {
                    // 判断是否包含大写字母
                    if (/[A-Z]/.test(input)) {
                        return 3; // 包含大写字母，返回 3
                    } else {
                        return 2; // 包含数字但不包含大写字母，返回 2
                    }
                } else {
                    return 1; // 不包含数字，返回 1
                }
            } else {
                return 0; // 不满足长度要求，返回 0
            }
        }
    }
}